import React from 'react';

export const HamburguerIcon = () => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 13.6816C0.75 13.2674 1.08579 12.9316 1.5 12.9316H16.5C16.9142 12.9316 17.25 13.2674 17.25 13.6816C17.25 14.0959 16.9142 14.4316 16.5 14.4316H1.5C1.08579 14.4316 0.75 14.0959 0.75 13.6816Z"
      fill="#3F465A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 7.68164C0.75 7.26743 1.08579 6.93164 1.5 6.93164H16.5C16.9142 6.93164 17.25 7.26743 17.25 7.68164C17.25 8.09585 16.9142 8.43164 16.5 8.43164H1.5C1.08579 8.43164 0.75 8.09585 0.75 7.68164Z"
      fill="#3F465A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 1.68164C0.75 1.26743 1.08579 0.931641 1.5 0.931641H16.5C16.9142 0.931641 17.25 1.26743 17.25 1.68164C17.25 2.09585 16.9142 2.43164 16.5 2.43164H1.5C1.08579 2.43164 0.75 2.09585 0.75 1.68164Z"
      fill="#3F465A"
    />
  </svg>
);
