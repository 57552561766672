import { OverlayContainer } from './styles';

interface OverlayProps extends React.HTMLAttributes<HTMLDivElement> {
  isActive?: boolean;
}

export function Overlay({ isActive = true }: OverlayProps) {
  if (!isActive) return null;

  return (
    <OverlayContainer
      aria-hidden
      onClick={event => {
        event.stopPropagation();
      }}
      onTouchStart={event => {
        event.stopPropagation();
      }}
    />
  );
}
