import { ReactNode } from 'react';
import { CSS, Flex } from '@ghq-abi/design-system';

interface ProfileRootProps {
  children: ReactNode;
  css?: CSS;
}
export function ProfileGroup({ children, css }: ProfileRootProps) {
  return (
    <Flex css={{ alignItems: 'center', gap: '$md', ...css }}>{children}</Flex>
  );
}
