import { CSS, Text } from '@ghq-abi/design-system';
import { T } from '@tolgee/react';
import { SurveyType } from 'abi-lcm-common-types';

type AssessmentTypeProps = {
  type?: SurveyType;
  css?: CSS;
};

export function AssessmentType({ type, css }: AssessmentTypeProps) {
  return (
    <Text
      css={{
        display: 'block',
        fontSize: '$xs',
        color: '$gray575',
        fontWeight: '$normal',
        ...css,
      }}
    >
      <T keyName="common.assessment_type" ns="default" />
      <Text as="span" css={{ fontWeight: 'bold', fontSize: '$xs', ...css }}>
        : {type}
      </Text>
    </Text>
  );
}
