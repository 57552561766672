import { styled } from '@ghq-abi/design-system';

export const ContentBox = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$white',
  border: '1px solid #E2E4E8',
  borderRadius: '$4',
  py: '$md',
  px: '$sm',
  width: '$full',
  '@lg': {
    p: '$md',
  },
});
