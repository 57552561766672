import React from 'react';
import { useDrop } from 'react-dnd';
import { Box } from '@ghq-abi/design-system';

import { DroppableProps } from './types';

// Define the droppable component
export function Droppable<T>({ onDrop, children, item }: DroppableProps<T>) {
  const [, dropRef] = useDrop({
    accept: item.type, // Replace with your specific item type
    drop: (target: T) => {
      onDrop(target);
    },
  });

  return <Box ref={dropRef}>{children}</Box>;
}
