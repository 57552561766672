import { styled } from '@ghq-abi/design-system';

export const ClickableTooltipTrigger = styled('button', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'help',
});
