import { styled, Text } from '@ghq-abi/design-system';

export const FooterText = styled(Text, {
  textAlign: 'center',
  color: '$black',
  fontSize: '$1',
  fontWeight: '$medium',
});

export const LinkToServiceNow = styled('a', {
  color: '#0053ad',
  textDecoration: 'underline',
  fontSize: '$1',
  fontWeight: '$medium',
});
