import * as React from 'react';
import { useEffectOnce } from 'react-use';

type AuthContextData = {
  notifyChangedProxy: () => void;
};

type AuthProviderProps = {
  children: React.ReactNode;
};

const AuthContext = React.createContext({} as AuthContextData);

let authChannel: BroadcastChannel;

export function AuthProvider({ children }: AuthProviderProps) {
  useEffectOnce(() => {
    function handleIncomingMessage(e: MessageEvent) {
      switch (e.data) {
        case 'changedProxy':
          window.location.reload();
          break;

        default:
          break;
      }
    }

    authChannel = new BroadcastChannel('@ghq/auth');
    authChannel.addEventListener('message', handleIncomingMessage);

    return () => {
      authChannel.removeEventListener('message', handleIncomingMessage);
    };
  });

  function notifyChangedProxy() {
    authChannel.postMessage('changedProxy');
  }

  const providerValue = React.useMemo(() => ({ notifyChangedProxy }), []);

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
