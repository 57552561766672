import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export function useRouteChanges() {
  const router = useRouter();

  const [isChanging, setIsChanging] = useState(false);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setIsChanging(true);
    };

    const handleRouteChangeComplete = () => {
      setIsChanging(false);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router]);

  return {
    isChanging,
  };
}
