type SaveProps = {
  size?: number;
  color?: string;
};

export function Save({ color = 'white', size = 20 }: SaveProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      color={color}
    >
      <path
        d="M3 5C3 3.89543 3.89543 3 5 3H13.3787C13.9091 3 14.4178 3.21071 14.7929 3.58579L16.4142 5.20711C16.7893 5.58218 17 6.09089 17 6.62132V15C17 16.1046 16.1046 17 15 17H5C3.89543 17 3 16.1046 3 15V5ZM5 4C4.44772 4 4 4.44772 4 5V15C4 15.5523 4.44772 16 5 16V11.5C5 10.6716 5.67157 10 6.5 10H13.5C14.3284 10 15 10.6716 15 11.5V16C15.5523 16 16 15.5523 16 15V6.62132C16 6.3561 15.8946 6.10175 15.7071 5.91421L14.0858 4.29289C13.8983 4.10536 13.6439 4 13.3787 4H13V6.5C13 7.32843 12.3284 8 11.5 8H7.5C6.67157 8 6 7.32843 6 6.5V4H5ZM7 4V6.5C7 6.77614 7.22386 7 7.5 7H11.5C11.7761 7 12 6.77614 12 6.5V4H7ZM14 16V11.5C14 11.2239 13.7761 11 13.5 11H6.5C6.22386 11 6 11.2239 6 11.5V16H14Z"
        fill="currentColor"
      />
    </svg>
  );
}
