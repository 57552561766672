import { CgSpinner } from 'react-icons/cg';
import { keyframes, styled, theme } from '@ghq-abi/design-system';

const spinAnimation = keyframes({
  '0%': { transform: 'rotate(0)' },
  '50%': { transform: 'rotate(180deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

export const Spinner = styled(CgSpinner, {
  marginLeft: theme.space.sm,
  animationName: `${spinAnimation}`,
  animationDuration: '800ms',
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
  willChange: 'transform',
  color: '$gray750',
});
