export const NOMINATION_STATUS_CONFIG_STYLE = {
  NOT_SUBMITTED: {
    label: 'not_submitted',
    color: '#3F465A',
  },
  PENDING_MANAGERS_APPROVAL: {
    label: 'pending_managers_approval',
    color: '#E3C90E',
  },
  SUBMITTED: {
    label: 'submitted',
    color: '#277B14',
  },
  FEEDBACK_GIVEN: {
    label: 'feedback_given',
    color: '#FFAD0D',
  },
};
