import { BsThreeDotsVertical } from 'react-icons/bs';
import { Button, Dropdown } from '@ghq-abi/design-system';

interface MassActionItem {
  label: string;
  value: string;
  onSelect: (value: string) => void;
}

interface MassActionDropdownProps {
  title: string;
  isDisabled?: boolean;
  items: Array<MassActionItem>;
}
export function MassActionDropdown({
  title,
  items,
  isDisabled,
}: MassActionDropdownProps) {
  return (
    <Dropdown>
      <Dropdown.Trigger
        asChild
        disabled={isDisabled}
        css={{ width: 'fit-content' }}
      >
        <Button
          disabled={isDisabled}
          variant="secondary"
          leftIcon={<BsThreeDotsVertical style={{ flexShrink: 0 }} />}
        >
          {title}
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content css={{ zIndex: '$dropdown' }}>
        {items.map(item => (
          <Dropdown.Item
            key={item.label}
            onSelect={() => item.onSelect(item.value)}
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Content>
    </Dropdown>
  );
}
