import * as React from 'react';

type BadgeProps = {
  size?: number;
  color?: string;
};

export function Badge({ size = 24, color = '#ffffff' }: BadgeProps) {
  const id = React.useId();

  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6.5 2C6.22386 2 6 2.22386 6 2.5C6 2.77614 6.22386 3 6.5 3H9.5C9.77614 3 10 2.77614 10 2.5C10 2.22386 9.77614 2 9.5 2H6.5Z"
        fill={color}
      />
      <path
        d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
        fill={color}
      />
      <path
        d="M4.5 0C3.11929 0 2 1.11929 2 2.5V14C2 15.1046 2.89543 16 4 16H12C13.1046 16 14 15.1046 14 14V2.5C14 1.11929 12.8807 0 11.5 0H4.5ZM3 2.5C3 1.67157 3.67157 1 4.5 1H11.5C12.3284 1 13 1.67157 13 2.5V13.295C12.8152 13.1446 12.5619 12.9719 12.2236 12.8028C11.392 12.387 10.0628 12 8 12C5.9372 12 4.60796 12.387 3.77639 12.8028C3.43811 12.9719 3.18477 13.1446 3 13.295V2.5Z"
        fill={color}
      />
    </svg>
  );
}
