import * as React from 'react';
import { useSession } from 'next-auth/react';

const MICROSOFT_LOGOUT_URL = `https://login.microsoftonline.com/${
  process.env.NEXT_PUBLIC_AZURE_AD_TENANT_ID ?? ''
}/oauth2/logout`;

export function useLogout() {
  const session = useSession();

  const [prevStatus, setPrevStatus] = React.useState(session.status);

  if (prevStatus !== session.status) {
    const isLoggingOut =
      session.status === 'unauthenticated' && prevStatus === 'authenticated';

    if (isLoggingOut) {
      document.location.replace(MICROSOFT_LOGOUT_URL);
    }

    setPrevStatus(session.status);
  }
}
