export function IdpIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.6875 1.125C1.37684 1.125 1.125 1.37684 1.125 1.6875V5.0625C1.125 5.37316 0.87316 5.625 0.5625 5.625C0.25184 5.625 0 5.37316 0 5.0625V1.6875C0 0.75552 0.75552 0 1.6875 0H5.0625C5.37316 0 5.625 0.25184 5.625 0.5625C5.625 0.87316 5.37316 1.125 5.0625 1.125H1.6875Z"
        fill="#191F2E"
      />
      <path
        d="M12.375 0.5625C12.375 0.25184 12.6268 0 12.9375 0H16.3125C17.2445 0 18 0.75552 18 1.6875V5.0625C18 5.37316 17.7482 5.625 17.4375 5.625C17.1268 5.625 16.875 5.37316 16.875 5.0625V1.6875C16.875 1.37684 16.6232 1.125 16.3125 1.125H12.9375C12.6268 1.125 12.375 0.87316 12.375 0.5625Z"
        fill="#191F2E"
      />
      <path
        d="M0.5625 12.375C0.87316 12.375 1.125 12.6268 1.125 12.9375V16.3125C1.125 16.6232 1.37684 16.875 1.6875 16.875H5.0625C5.37316 16.875 5.625 17.1268 5.625 17.4375C5.625 17.7482 5.37316 18 5.0625 18H1.6875C0.75552 18 0 17.2445 0 16.3125V12.9375C0 12.6268 0.25184 12.375 0.5625 12.375Z"
        fill="#191F2E"
      />
      <path
        d="M17.4375 12.375C17.7482 12.375 18 12.6268 18 12.9375V16.3125C18 17.2445 17.2445 18 16.3125 18H12.9375C12.6268 18 12.375 17.7482 12.375 17.4375C12.375 17.1268 12.6268 16.875 12.9375 16.875H16.3125C16.6232 16.875 16.875 16.6232 16.875 16.3125V12.9375C16.875 12.6268 17.1268 12.375 17.4375 12.375Z"
        fill="#191F2E"
      />
      <path
        d="M3.375 15.75C3.375 15.75 2.25 15.75 2.25 14.625C2.25 13.5 3.375 10.125 9 10.125C14.625 10.125 15.75 13.5 15.75 14.625C15.75 15.75 14.625 15.75 14.625 15.75H3.375Z"
        fill="#191F2E"
      />
      <path
        d="M12.375 5.625C12.375 7.48896 10.864 9 9 9C7.13604 9 5.625 7.48896 5.625 5.625C5.625 3.76104 7.13604 2.25 9 2.25C10.864 2.25 12.375 3.76104 12.375 5.625Z"
        fill="#191F2E"
      />
    </svg>
  );
}
