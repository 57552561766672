import { useSessionInfo } from '~/app/contexts/SessionInfoContext';

const CEO = 'CEO';

export function useIsCEO() {
  const { sessionInfo } = useSessionInfo();

  const isCEO =
    sessionInfo?.user?.proxiedAs?.band === CEO ||
    sessionInfo?.user?.band === CEO;

  return isCEO;
}
