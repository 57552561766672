import { useMemo } from 'react';
import { parseISO } from 'date-fns';

import { useTolgeeLocale } from '~/shared/hooks';
import { formatDate } from '~/shared/utils/date';

type FormattedDateProps = {
  date: string;
  options?: Intl.DateTimeFormatOptions;
};

export function FormattedDate({ date, options }: FormattedDateProps) {
  const locale = useTolgeeLocale();

  const formattedDate = useMemo(() => {
    const dateObject = parseISO(date.toString() ?? '');
    return formatDate(locale, dateObject, options);
  }, [date, locale, options]);

  return <span>{formattedDate}</span>;
}
