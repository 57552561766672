type ProxyProps = {
  size?: number;
  color?: string;
};

export function SignOut({ size = 16, color = 'currentColor' }: ProxyProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      color={color}
    >
      <path
        d="M6 8C6 8.27614 6.22386 8.5 6.5 8.5H12.2929L10.1464 10.6464C9.95118 10.8417 9.95118 11.1583 10.1464 11.3536C10.3417 11.5488 10.6583 11.5488 10.8536 11.3536L13.8536 8.35355C14.0488 8.15829 14.0488 7.84171 13.8536 7.64645L10.8536 4.64645C10.6583 4.45118 10.3417 4.45118 10.1464 4.64645C9.95118 4.84171 9.95118 5.15829 10.1464 5.35355L12.2929 7.5H6.5C6.22386 7.5 6 7.72386 6 8Z"
        fill="#191F2E"
      />
      <path
        d="M3.5 15C3.22386 15 3 14.7761 3 14.5L3 1.5C3 1.22386 3.22386 1 3.5 1C3.77614 1 4 1.22386 4 1.5V14.5C4 14.7761 3.77614 15 3.5 15Z"
        fill="#191F2E"
      />
    </svg>
  );
}
