import { useQuery } from '@tanstack/react-query';
import { ProcessDateNameEnum } from 'abi-lcm-common-types';

import { lcmApi } from '../services/api';

export interface GetProcessDateApiResponse {
  start: string;
  end: string;
}

type ProcessNameWithInterval = {
  process: ProcessDateNameEnum;
} & GetProcessDateApiResponse;

type ProcessDatesBoolean = {
  [key in `isWithin${ProcessDateNameEnum}Deadline`]?: boolean;
};

type ProcessDatesInterval = {
  [key in ProcessDateNameEnum]?: GetProcessDateApiResponse;
};

const getProcessBooleanName = (
  process: ProcessDateNameEnum
): keyof ProcessDatesBoolean => `isWithin${process}Deadline`;

const getProcessDate = async (
  processNames: ProcessDateNameEnum[]
): Promise<ProcessNameWithInterval[]> => {
  const promises = processNames.map(async process => {
    const { data } = await lcmApi.get<GetProcessDateApiResponse>(
      `/process-dates/${process}`
    );

    const datesWithProcess = {
      ...data,
      process,
    };

    return datesWithProcess;
  });

  return Promise.all(promises);
};

const isBetweenInterval = (processData: ProcessNameWithInterval) => {
  if (processData) {
    const today = new Date();
    const processStartDate = new Date(processData?.start);
    const processEndDate = new Date(processData?.end);

    return today >= processStartDate && today <= processEndDate;
  }

  return false;
};

export function useProcessDates(processNames: ProcessDateNameEnum[] = []) {
  const query = useQuery<ProcessNameWithInterval[]>({
    queryKey: processNames,
    queryFn: () => getProcessDate(processNames),
    enabled: !!processNames.length,
    staleTime: Infinity,
  });

  const booleanDeadlines: ProcessDatesBoolean = {};
  const deadlines: ProcessDatesInterval = {};

  query?.data?.forEach(processData => {
    const { process, ...dates } = processData;
    const isWithinDeadlineValue = isBetweenInterval(processData);
    const processName = getProcessBooleanName(process);

    booleanDeadlines[processName] = isWithinDeadlineValue;
    deadlines[process] = dates;
  });

  return Object.assign(query, {
    ...booleanDeadlines,
    deadlines,
  });
}
