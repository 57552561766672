import { useReducer } from 'react';
import { useDebounce } from 'react-use';

type InitialStateProp = {
  query?: string;
  status?: string;
  debouncedQuery?: string;
};

const initialState: InitialStateProp = {
  query: undefined,
  status: undefined,
  debouncedQuery: undefined,
};

type FilterAction = {
  type: 'search' | 'debounce' | 'status';
  value?: string;
};

const filterReduce = (state: InitialStateProp, action: FilterAction) => {
  switch (action.type) {
    case 'search':
      return { ...state, query: action.value };
    case 'status':
      return { ...state, status: action.value };
    case 'debounce':
      return { ...state, debouncedQuery: action.value };

    default:
      return state;
  }
};

export function useDebouncedSearch() {
  const [search, dispatch] = useReducer(filterReduce, initialState);

  useDebounce(
    () => {
      dispatch({ type: 'debounce', value: search.query });
    },
    500,
    [search.query]
  );

  return { search, dispatch };
}
