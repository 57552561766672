import type { QueryClientConfig } from '@tanstack/react-query';

export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 30 * 1000, // 30 seconds
      ...(process.env.NEXT_PUBLIC_ENV === 'production'
        ? {
            staleTime: 60 * 1000 * 5, // 5 minutes
          }
        : {}),
    },
  },
};
