import { Text } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

type PositionAndZoneProps = {
  band?: string;
};
export function Band({ band }: PositionAndZoneProps) {
  const { t } = useTranslate('default');
  return (
    <Text
      css={{
        display: 'block',
        fontSize: '$xs',
        color: '$gray575',
        fontWeight: '$normal',
      }}
    >
      {t('common.band')}:
      <Text as="span" css={{ fontWeight: '$bold', fontSize: '$xs', ml: '$xs' }}>
        {band}
      </Text>
    </Text>
  );
}
