export function isClient() {
  return typeof window !== 'undefined';
}

export function isServer() {
  return typeof window === 'undefined';
}

export function getEnv() {
  const appEnv = String(process.env.NEXT_PUBLIC_ENV).toLowerCase();

  if (appEnv === 'staging') {
    return 'staging';
  }

  if (appEnv === 'production') {
    return 'production';
  }

  if (appEnv === 'development') {
    return 'development';
  }

  if (appEnv === 'local') {
    return 'local';
  }

  return 'unknown';
}

export function isProduction() {
  return getEnv() === 'production';
}

export function isDevelopment() {
  return getEnv() === 'development';
}

export function isTest() {
  return getEnv() === 'staging';
}

export function isLocal() {
  return getEnv() === 'local';
}
