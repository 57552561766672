import * as React from 'react';
import type { JWT } from 'next-auth/jwt';

import packageJson from '../../../package.json';
import { getEnv, isLocal } from '../utils/environments';

export function useDatadog(locale: string, session?: JWT) {
  React.useEffect(() => {
    async function init() {
      const { datadogRum } = await import('@datadog/browser-rum');

      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID ?? '',
        clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? '',
        site: 'datadoghq.com',
        service: 'lcm',
        env: getEnv(),
        version: packageJson.version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        enableExperimentalFeatures: ['clickmap'],
        defaultPrivacyLevel: 'allow',
      });

      datadogRum.startSessionReplayRecording();
    }

    async function trackUserSession(userSession: JWT) {
      const { datadogRum } = await import('@datadog/browser-rum');

      datadogRum.setUser({
        email: userSession?.user?.email ?? '',
        id: userSession?.user?.employeeId ?? '',
        name: userSession?.user?.name ?? '',
        upn: userSession?.user?.upn ?? '',
      });
      datadogRum.setUserProperty('globalId', userSession?.user?.globalId);
      datadogRum.setUserProperty('zone', userSession?.user?.zone);
      datadogRum.setUserProperty(
        'roles',
        JSON.stringify(userSession?.user?.roles)
      );
      datadogRum.setUserProperty(
        'proxiedAs',
        JSON.stringify(userSession?.user?.proxiedAs)
      );
      datadogRum.setUserProperty('locale', locale);
      datadogRum.setUserProperty('session', JSON.stringify(userSession));
    }

    if (
      !window.DD_RUM &&
      typeof process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID === 'string' &&
      typeof process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN === 'string' &&
      !isLocal()
    ) {
      init();
    }

    if (session && !isLocal()) {
      trackUserSession(session);
    }
  }, [locale, session]);
}
