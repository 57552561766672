import { CSS, Heading } from '@ghq-abi/design-system';

type FirstAndLastNameProps = {
  firstName?: string;
  lastName: string;
  highlightFirstName?: boolean;
  name?: string;
  css?: CSS;
};

export function FirstAndLastName({
  lastName,
  firstName,
  highlightFirstName,
  name,
  css,
}: FirstAndLastNameProps) {
  return (
    <Heading
      size="sm"
      title={name}
      css={{
        lineHeight: '$normal',
        color: '$gray750',
        fontSize: '$sm',
        limitLines: 2,
        span: { fontWeight: '$medium' },
        ...css,
      }}
    >
      <span>
        {highlightFirstName ? <b>{firstName}</b> : firstName} {lastName}
      </span>
    </Heading>
  );
}
