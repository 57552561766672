import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { GetAllLanguagesResponse } from 'abi-lcm-common-types';

import { useToast } from '~/app/contexts/ToastContext';
import { lcmApi } from '~/shared/services/api';

import { GET_AVAILABLE_LANGUAGES_QUERY_KEY } from './queryKeys';

async function getAvailableLanguages(): Promise<GetAllLanguagesResponse> {
  const response = await lcmApi.get<GetAllLanguagesResponse>('/language');

  return response.data;
}

export function useGetAvailableLanguages() {
  const { t } = useTranslate('web-app');
  const toast = useToast();

  return useInfiniteQuery({
    queryKey: [GET_AVAILABLE_LANGUAGES_QUERY_KEY],
    queryFn: getAvailableLanguages,
    onError: () => {
      toast.add({
        type: 'error',
        description: t('language.error-get-available-languages'),
      });
    },
  });
}
