import { Flex, styled } from '@ghq-abi/design-system';
import {
  AdminEmployeeListSanitize,
  EmployeeLcmFreezeNominationAssigned,
} from 'abi-lcm-common-types';

import { PersonalProfile } from '../PersonalProfile';

export const SimpleCard = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$sm',
  padding: '$sm',
  borderRadius: '$2',
  background: '#EEEFF2',
});

type SimpleEmployeeCardProps = {
  employee: EmployeeLcmFreezeNominationAssigned | AdminEmployeeListSanitize;
  action?: React.ReactNode;
  children?: React.ReactNode;
};

export function SimpleEmployeeCard({
  employee,
  action,
  children,
}: SimpleEmployeeCardProps) {
  return (
    <SimpleCard>
      <Flex>
        <Flex gap="sm" align="center" css={{ flex: 1 }}>
          <PersonalProfile
            name={employee?.name}
            firstName={employee?.firstName}
            lastName={employee?.lastName}
            globalId={employee.employeeGlobalId}
            jobTitle={`${employee?.position} - ${employee.zoneAcronym}`}
          />
        </Flex>
        <Flex align="center">{action && action}</Flex>
      </Flex>
      {children && <Flex>{children}</Flex>}
    </SimpleCard>
  );
}
