import * as React from 'react';

type HomeProps = {
  size?: number;
  color?: string;
};

export function Home({ size = 24, color = '#191F2E' }: HomeProps) {
  const id = React.useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 ${size} ${size}`}
      color={color}
    >
      <g fill="currentColor" clipPath={`url(#${id})`}>
        <path d="M22.473 11.527l-10-10a.667.667 0 00-.94 0l-10 10a.667.667 0 00.94.94L12 2.94l9.527 9.534a.666.666 0 00.94-.94l.006-.007z" />
        <path d="M18.667 21.333h-3.334v-6.666H8.667v6.666H5.333V12L4 13.333v8a1.333 1.333 0 001.333 1.334H10V16h4v6.666h4.667A1.334 1.334 0 0020 21.334v-8.16l-1.333-1.333v9.493z" />
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="currentColor" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
