import React from 'react';

export const CheersHubIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_22743_33617)">
      <path
        d="M18.0769 19.6945C21.3651 19.6945 24.0308 17.0316 24.0308 13.7467C24.0308 10.4618 21.3651 7.79883 18.0769 7.79883C14.7887 7.79883 12.123 10.4618 12.123 13.7467C12.123 17.0316 14.7887 19.6945 18.0769 19.6945Z"
        fill="url(#paint0_linear_22743_33617)"
      />
      <path
        d="M12.862 18.7807C15.0467 18.7807 16.8178 17.0115 16.8178 14.829C16.8178 12.6465 15.0467 10.8772 12.862 10.8772C10.6773 10.8772 8.90625 12.6465 8.90625 14.829C8.90625 17.0115 10.6773 18.7807 12.862 18.7807Z"
        fill="#3F465A"
      />
      <path
        d="M6.16159 7.79428C7.73946 7.79428 9.2527 8.42045 10.3684 9.53504C11.4842 10.6496 12.111 12.1614 12.111 13.7376C12.111 12.9566 12.2649 12.1833 12.5641 11.4617C12.8633 10.7402 13.3018 10.0845 13.8546 9.53227C14.4075 8.98001 15.0637 8.54194 15.786 8.24306C16.5083 7.94418 17.2825 7.79035 18.0643 7.79035C18.8461 7.79035 19.6202 7.94418 20.3425 8.24306C21.0648 8.54194 21.7211 8.98001 22.2739 9.53227C22.8267 10.0845 23.2652 10.7402 23.5644 11.4617C23.8636 12.1833 24.0176 12.9566 24.0176 13.7376C24.0634 12.147 23.7892 10.5633 23.2113 9.08037C22.6335 7.59745 21.7637 6.24542 20.6534 5.10434C19.5431 3.96325 18.2149 3.05631 16.7475 2.4372C15.2801 1.81809 13.7032 1.4994 12.1103 1.5C10.5173 1.5006 8.94071 1.82048 7.47375 2.44069C6.00678 3.06091 4.67929 3.96885 3.56987 5.11077C2.46045 6.25269 1.59165 7.60538 1.01492 9.08874C0.438177 10.5721 0.165224 12.156 0.212214 13.7466C0.211033 12.9654 0.364047 12.1915 0.662503 11.4694C0.960958 10.7473 1.399 10.0911 1.95157 9.53822C2.50413 8.98537 3.16039 8.54679 3.88278 8.24754C4.60518 7.9483 5.37955 7.79427 6.16159 7.79428Z"
        fill="#F2DC39"
      />
      <path
        d="M10.38 17.9519C12.7052 15.6291 12.7052 11.8631 10.38 9.54033C8.05492 7.21755 4.28514 7.21755 1.96002 9.54033C-0.365111 11.8631 -0.365111 15.6291 1.96002 17.9519C4.28514 20.2746 8.05492 20.2746 10.38 17.9519Z"
        fill="#F2DC39"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_22743_33617"
        x1="22.4233"
        y1="17.4136"
        x2="-1.49658"
        y2="-2.80868"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9A216" />
        <stop offset="0.22" stopColor="#E6BA0C" />
        <stop offset="0.44" stopColor="#EFCB05" />
        <stop offset="0.69" stopColor="#F4D501" />
        <stop offset="1" stopColor="#F6D800" />
      </linearGradient>
      <clipPath id="clip0_22743_33617">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
