import { useEffect } from 'react';

export function usePreventXTouch() {
  useEffect(() => {
    const targetElement = document.body;

    let xStart = 0;
    let yStart = 0;

    function onTouchStart(e: TouchEvent) {
      // Store the initial touch position
      xStart = e.touches[0].clientX;
      yStart = e.touches[0].clientY;
    }

    function onTouchMove(e: TouchEvent) {
      const xMovement = Math.abs(e.touches[0].clientX - xStart);
      const yMovement = Math.abs(e.touches[0].clientY - yStart);

      if (xMovement > yMovement) {
        // If the horizontal movement is greater than vertical movement,
        // prevent the default touchmove behavior
        e.preventDefault();
      }
    }
    // Add event listener for touchstart event
    targetElement.addEventListener('touchstart', onTouchStart, {
      passive: false,
    });

    // Add event listener for touchmove event
    targetElement.addEventListener('touchmove', onTouchMove, {
      passive: false,
    });

    return () => {
      targetElement.removeEventListener('touchstart', onTouchStart);
      targetElement.removeEventListener('touchmove', onTouchMove);
    };
  }, []);
}
