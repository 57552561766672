type UndoProps = {
  size?: number;
  color?: string;
};

export function Undo({ color = '$blue350', size = 16 }: UndoProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      color={color}
    >
      <path
        d="M13.9997 7.3335C13.8229 7.3335 13.6533 7.40373 13.5283 7.52876C13.4032 7.65378 13.333 7.82335 13.333 8.00016C13.3409 9.24885 12.9132 10.4612 12.1234 11.4285C11.3337 12.3957 10.2314 13.0573 9.00632 13.2992C7.78127 13.5412 6.5102 13.3484 5.41201 12.754C4.31383 12.1597 3.45728 11.2009 2.98989 10.043C2.5225 8.88506 2.47354 7.60037 2.85142 6.41021C3.22931 5.22005 4.01039 4.19892 5.06014 3.52268C6.1099 2.84645 7.36261 2.55744 8.60252 2.70544C9.84243 2.85344 10.9919 3.42918 11.853 4.3335H10.253C10.0762 4.3335 9.90663 4.40373 9.78161 4.52876C9.65658 4.65378 9.58634 4.82335 9.58634 5.00016C9.58634 5.17697 9.65658 5.34654 9.78161 5.47157C9.90663 5.59659 10.0762 5.66683 10.253 5.66683H13.273C13.4498 5.66683 13.6194 5.59659 13.7444 5.47157C13.8694 5.34654 13.9397 5.17697 13.9397 5.00016V2.00016C13.9397 1.82335 13.8694 1.65378 13.7444 1.52876C13.6194 1.40373 13.4498 1.3335 13.273 1.3335C13.0962 1.3335 12.9266 1.40373 12.8016 1.52876C12.6766 1.65378 12.6063 1.82335 12.6063 2.00016V3.18016C11.4961 2.11887 10.0535 1.47346 8.52235 1.35305C6.9912 1.23263 5.46543 1.64459 4.20296 2.51929C2.94048 3.39399 2.01875 4.67777 1.59356 6.15362C1.16837 7.62948 1.26582 9.20688 1.86942 10.6192C2.47301 12.0315 3.54574 13.1921 4.90627 13.9047C6.2668 14.6174 7.83168 14.8384 9.33637 14.5305C10.8411 14.2225 12.1933 13.4045 13.1644 12.2146C14.1356 11.0247 14.6661 9.53605 14.6663 8.00016C14.6663 7.82335 14.5961 7.65378 14.4711 7.52876C14.3461 7.40373 14.1765 7.3335 13.9997 7.3335Z"
        fill="#325A6D"
      />
    </svg>
  );
}
