import * as React from 'react';
import { IoMdClose } from 'react-icons/io';
import { Box, CSS, Flex, Text } from '@ghq-abi/design-system';

type ChipProps = {
  onClick: () => void;
  text: string;
  css?: CSS;
  textCss?: CSS;
};

export function Chip({ onClick, text, css, textCss }: ChipProps) {
  return (
    <Flex
      align="center"
      gap="sm"
      css={{
        backgroundColor: '#325A6D26',
        height: '$6',
        px: '$2',
        fontSize: '$1',
        borderRadius: '$6',
        color: '$blue350',
        width: 'fit-content',
        transition: '$quickly-linear',
        '&:hover': {
          backgroundColor: '$blue350',
          color: '$white',
        },
        ...css,
      }}
    >
      <Text
        css={{
          color: 'inherit',
          fontSize: 'inherit',
          lineHeight: '$5',
          ...textCss,
        }}
      >
        {text}
      </Text>
      <Box
        css={{
          height: '$4',
          cursor: 'pointer',
        }}
        role="button"
        onClick={onClick}
      >
        <IoMdClose size={16} />
      </Box>
    </Flex>
  );
}
