import * as React from 'react';
import { RiErrorWarningFill } from 'react-icons/ri';
import { CSS, Flex, Text, theme, Tooltip } from '@ghq-abi/design-system';

import { useClickableTooltip } from '~/shared/hooks';

import { ClickableTooltipTrigger } from '../ClickableTooltipTrigger';

type ErrorTooltipProps = {
  title: string;
  message: string;
  align?: 'center' | 'end' | 'start';
  iconSize?: number;
  buttonCss?: CSS;
};

export function ErrorTooltip({
  title,
  message,
  align = 'end',
  iconSize = 18,
  buttonCss,
}: ErrorTooltipProps) {
  const { rootTooltipProps, openTooltip } = useClickableTooltip();

  return (
    <Tooltip
      {...rootTooltipProps}
      align={align}
      side="top"
      showArrow
      alignOffset={-iconSize}
      content={
        <Flex
          direction="column"
          gap="md"
          align={align}
          css={{ width: 'calc($3xs + $4-5)' }}
        >
          <Text as="strong" css={{ color: '$white', fontSize: '$2' }}>
            {title}
          </Text>
          <Text
            css={{
              color: '$white',
              fontSize: '$1',
              textAlign: align,
              mb: '$3',
            }}
          >
            {message}
          </Text>
        </Flex>
      }
    >
      <ClickableTooltipTrigger
        onClick={openTooltip}
        onTouchEnd={e => e.stopPropagation()}
        css={{
          ml: '$sm',
          ...buttonCss,
        }}
      >
        <RiErrorWarningFill
          size={iconSize}
          color={theme.colors.gray780.value}
        />
      </ClickableTooltipTrigger>
    </Tooltip>
  );
}
