import { styled } from '@ghq-abi/design-system';

export const OverlayContainer = styled('div', {
  backgroundColor: 'rgba(0, 0, 0, 0.44)',

  width: '100%',
  height: '100%',

  zIndex: '$overlay',

  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});
