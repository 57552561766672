type SubmitDocumentProps = {
  size?: number;
  color?: string;
};

export function SubmitDocument({
  size = 16,
  color = 'white',
}: SubmitDocumentProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
    >
      <g id="submit-document 1">
        <path
          id="Vector"
          d="M13.3332 8.66668V3.83236C13.3332 3.72628 13.291 3.62454 13.216 3.54952L11.117 1.4505C11.042 1.37548 10.9402 1.33334 10.8342 1.33334H3.0665C2.84559 1.33334 2.6665 1.51243 2.6665 1.73334V14.2667C2.6665 14.4876 2.84559 14.6667 3.0665 14.6667H9.33317"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M10.6665 1.33334V3.60001C10.6665 3.82092 10.8456 4.00001 11.0665 4.00001H13.3332"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M10.6665 12.6667H14.6665M14.6665 12.6667L12.6665 10.6667M14.6665 12.6667L12.6665 14.6667"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
