import * as React from 'react';
import { Avatar as DSAvatar } from '@ghq-abi/design-system';

type AvatarProps = React.ComponentProps<typeof DSAvatar> & {
  globalId?: string;
};

export function Avatar({ globalId, ...rest }: AvatarProps) {
  return (
    <DSAvatar
      src={
        process.env.NEXT_PUBLIC_PEOPLE_PLATFORM_PHOTO_API_URL && globalId
          ? `${process.env.NEXT_PUBLIC_PEOPLE_PLATFORM_PHOTO_API_URL}/thumb/${globalId}`
          : undefined
      }
      {...rest}
    />
  );
}
