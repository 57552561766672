import { useState } from 'react';

export function useDisclosure(startsOpen = false) {
  const [isOpen, setIsOpen] = useState(startsOpen);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const toggle = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  return {
    isOpen,
    open,
    close,
    toggle,
  };
}
