import { BsThreeDotsVertical } from 'react-icons/bs';
import { Button, Dropdown } from '@ghq-abi/design-system';

export interface EvaluationActionItem {
  label: string;
  value: string;
  textColor?: string;
  onSelect: (value: string) => void;
}

interface EvaluationActionDropdownProps {
  isDisabled?: boolean;
  items: Array<EvaluationActionItem>;
}
export function EvaluationActionDropdown({
  items,
  isDisabled,
}: EvaluationActionDropdownProps) {
  return (
    <Dropdown>
      <Dropdown.Trigger
        asChild
        disabled={isDisabled}
        css={{ width: 'fit-content' }}
      >
        <Button
          disabled={isDisabled}
          variant="secondary"
          css={{ boxShadow: 'none', pr: 0 }}
          leftIcon={<BsThreeDotsVertical style={{ flexShrink: 0 }} />}
        />
      </Dropdown.Trigger>
      <Dropdown.Content align="end" css={{ minWidth: 108, minHeight: 84 }}>
        {items.map(item => (
          <Dropdown.Item
            key={item.label}
            css={{ justifyContent: 'flex-end', color: item.textColor }}
            onSelect={() => item.onSelect(item.value)}
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Content>
    </Dropdown>
  );
}
