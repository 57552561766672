import { ReactNode } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { Box, CSS, Flex } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';
import { AdminEmployeeListSanitize } from 'abi-lcm-common-types';

import { DynamicTooltip } from '../DynamicTooltip';
import { Trash } from '../icons/Trash';
import { PersonalProfile } from '../PersonalProfile';

interface EmployeeCardProps {
  employee: AdminEmployeeListSanitize;
  children?: React.ReactNode;
  css?: CSS;
  rightIconsCss?: CSS;
  showEyeTooltip?: boolean;
  showDeleteIconButton?: boolean;
  renderAssignment?: ReactNode;
  customHeadingWidth?: string;
  onRemoveEmployee?: (employeeId: string) => void;
}
export function EmployeeCard({
  employee,
  css,
  children,
  showEyeTooltip = true,
  showDeleteIconButton = true,
  renderAssignment,
  customHeadingWidth = 'auto',
  rightIconsCss,
  onRemoveEmployee,
}: EmployeeCardProps) {
  const { t } = useTranslate('default');

  const employeeTooltipInfo = [
    {
      label: t('employee_tooltip_details.zone'),
      value: employee.zoneName ?? '-',
    },
    {
      label: t('employee_tooltip_details.function'),
      value: employee.function ?? '-',
    },
    {
      label: t('employee_tooltip_details.position'),
      value: employee.position ?? '-',
    },
    {
      label: t('employee_tooltip_details.email'),
      value: employee.email ?? '-',
    },
  ];

  return (
    <Flex
      align="center"
      justify="between"
      css={{
        padding: '$sm',
        bg: '#F3F3F3',
        ml: '$2-5',
        borderRadius: '$2',
        ...css,
      }}
    >
      <Flex
        align="center"
        justify="between"
        css={{
          width: '100%',
        }}
      >
        <PersonalProfile
          name={employee?.name}
          firstName={employee?.firstName}
          lastName={employee?.lastName}
          globalId={employee.employeeGlobalId}
          jobTitle={`${employee?.position} - ${employee.zoneAcronym}`}
          customHeadingWidth={customHeadingWidth}
        />
        <Flex css={{ gap: '$sm', '@lg': { gap: '0' } }}>
          {children && children}
          <Flex
            direction="column"
            gap="xs"
            justify="center"
            css={rightIconsCss}
          >
            {showDeleteIconButton && onRemoveEmployee && (
              <Box
                css={{
                  height: '16px',
                  cursor: 'pointer',
                }}
                role="button"
                onClick={() => onRemoveEmployee(employee.id)}
              >
                <Trash />
              </Box>
            )}
            {showEyeTooltip && (
              <DynamicTooltip items={employeeTooltipInfo}>
                <AiOutlineEye size={16} />
              </DynamicTooltip>
            )}
          </Flex>
        </Flex>
      </Flex>

      {renderAssignment && (
        <Flex
          css={{
            width: '100%',
            display: 'flex',
            '@lg': {
              display: 'none',
            },
          }}
        >
          {renderAssignment}
        </Flex>
      )}
    </Flex>
  );
}
