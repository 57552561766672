import { CSS, Flex, Text } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';
import { EvaluatorProfile } from 'abi-lcm-common-types';

import { ErrorTooltip } from '../../ErrorTooltip';

type PersonaProps = {
  persona?: EvaluatorProfile;
  tooltipMessage?: string;
  css?: CSS;
};

export function Persona({ persona, tooltipMessage, css }: PersonaProps) {
  const { t } = useTranslate('default');
  return (
    <Flex align="center">
      <Text
        css={{
          display: 'block',
          fontSize: '$xs',
          color: '$gray750',
          fontWeight: '$normal',
          ...css,
        }}
      >
        {`${t('persona.title')}: `}
        <Text as="span" css={{ fontSize: '$xs', ...css, fontWeight: 'bold' }}>
          {persona}
        </Text>
      </Text>
      {!!tooltipMessage && (
        <ErrorTooltip
          title={t('persona.title')}
          message={tooltipMessage}
          align="start"
        />
      )}
    </Flex>
  );
}
