type FilterCirclePros = {
  color?: string;
  size?: number;
};

export function FilterCircle({
  color = '#191F2E',
  size = 20,
}: FilterCirclePros) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 18.75C5.16751 18.75 1.25 14.8325 1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 14.8325 14.8325 18.75 10 18.75ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill={color}
      />
      <path
        d="M8.75 14.375C8.75 14.0298 9.02982 13.75 9.375 13.75H10.625C10.9702 13.75 11.25 14.0298 11.25 14.375C11.25 14.7202 10.9702 15 10.625 15H9.375C9.02982 15 8.75 14.7202 8.75 14.375Z"
        fill={color}
      />
      <path
        d="M6.25 10.625C6.25 10.2798 6.52982 10 6.875 10H13.125C13.4702 10 13.75 10.2798 13.75 10.625C13.75 10.9702 13.4702 11.25 13.125 11.25H6.875C6.52982 11.25 6.25 10.9702 6.25 10.625Z"
        fill={color}
      />
      <path
        d="M3.75 6.875C3.75 6.52982 4.02982 6.25 4.375 6.25H15.625C15.9702 6.25 16.25 6.52982 16.25 6.875C16.25 7.22018 15.9702 7.5 15.625 7.5H4.375C4.02982 7.5 3.75 7.22018 3.75 6.875Z"
        fill={color}
      />
    </svg>
  );
}
