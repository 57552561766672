export function Overview() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666748 1.33329C0.666748 1.15648 0.736986 0.986912 0.86201 0.861888C0.987035 0.736864 1.1566 0.666626 1.33341 0.666626H4.00008C4.17689 0.666626 4.34646 0.736864 4.47149 0.861888C4.59651 0.986912 4.66675 1.15648 4.66675 1.33329V4.66663C4.66675 4.84344 4.59651 5.01301 4.47149 5.13803C4.34646 5.26305 4.17689 5.33329 4.00008 5.33329H1.33341C1.1566 5.33329 0.987035 5.26305 0.86201 5.13803C0.736986 5.01301 0.666748 4.84344 0.666748 4.66663V1.33329ZM7.33341 1.33329C7.33341 1.15648 7.40365 0.986912 7.52868 0.861888C7.6537 0.736864 7.82327 0.666626 8.00008 0.666626H10.6667C10.8436 0.666626 11.0131 0.736864 11.1382 0.861888C11.2632 0.986912 11.3334 1.15648 11.3334 1.33329V2.66663C11.3334 2.84344 11.2632 3.01301 11.1382 3.13803C11.0131 3.26305 10.8436 3.33329 10.6667 3.33329H8.00008C7.82327 3.33329 7.6537 3.26305 7.52868 3.13803C7.40365 3.01301 7.33341 2.84344 7.33341 2.66663V1.33329ZM0.666748 8.66663C0.666748 8.48981 0.736986 8.32025 0.86201 8.19522C0.987035 8.0702 1.1566 7.99996 1.33341 7.99996H4.00008C4.17689 7.99996 4.34646 8.0702 4.47149 8.19522C4.59651 8.32025 4.66675 8.48981 4.66675 8.66663V10.6666C4.66675 10.8434 4.59651 11.013 4.47149 11.138C4.34646 11.2631 4.17689 11.3333 4.00008 11.3333H1.33341C1.1566 11.3333 0.987035 11.2631 0.86201 11.138C0.736986 11.013 0.666748 10.8434 0.666748 10.6666V8.66663ZM7.33341 6.66663C7.33341 6.48981 7.40365 6.32025 7.52868 6.19522C7.6537 6.0702 7.82327 5.99996 8.00008 5.99996H10.6667C10.8436 5.99996 11.0131 6.0702 11.1382 6.19522C11.2632 6.32025 11.3334 6.48981 11.3334 6.66663V10.6666C11.3334 10.8434 11.2632 11.013 11.1382 11.138C11.0131 11.2631 10.8436 11.3333 10.6667 11.3333H8.00008C7.82327 11.3333 7.6537 11.2631 7.52868 11.138C7.40365 11.013 7.33341 10.8434 7.33341 10.6666V6.66663Z"
        stroke="white"
        stroke-width="1.33333"
      />
    </svg>
  );
}
