import * as React from 'react';

type ReportsProps = {
  size?: number;
  color?: string;
};

export function Reports({ size = 24, color = '#191F2E' }: ReportsProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18 18"
      color={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 9.5H4.5V10.5H5.5V9.5ZM4.5 8.5C3.9477 8.5 3.5 8.9477 3.5 9.5V10.5C3.5 11.0523 3.9477 11.5 4.5 11.5H5.5C6.0523 11.5 6.5 11.0523 6.5 10.5V9.5C6.5 8.9477 6.0523 8.5 5.5 8.5H4.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 9.5H8.5V10.5H9.5V9.5ZM8.5 8.5C7.9477 8.5 7.5 8.9477 7.5 9.5V10.5C7.5 11.0523 7.9477 11.5 8.5 11.5H9.5C10.0523 11.5 10.5 11.0523 10.5 10.5V9.5C10.5 8.9477 10.0523 8.5 9.5 8.5H8.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 9.5H12.5V10.5H13.5V9.5ZM12.5 8.5C11.9477 8.5 11.5 8.9477 11.5 9.5V10.5C11.5 11.0523 11.9477 11.5 12.5 11.5H13.5C14.0523 11.5 14.5 11.0523 14.5 10.5V9.5C14.5 8.9477 14.0523 8.5 13.5 8.5H12.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 13.5H4.5V14.5H5.5V13.5ZM4.5 12.5C3.9477 12.5 3.5 12.9477 3.5 13.5V14.5C3.5 15.0523 3.9477 15.5 4.5 15.5H5.5C6.0523 15.5 6.5 15.0523 6.5 14.5V13.5C6.5 12.9477 6.0523 12.5 5.5 12.5H4.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 13.5H8.5V14.5H9.5V13.5ZM8.5 12.5C7.9477 12.5 7.5 12.9477 7.5 13.5V14.5C7.5 15.0523 7.9477 15.5 8.5 15.5H9.5C10.0523 15.5 10.5 15.0523 10.5 14.5V13.5C10.5 12.9477 10.0523 12.5 9.5 12.5H8.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 13.5H12.5V14.5H13.5V13.5ZM12.5 12.5C11.9477 12.5 11.5 12.9477 11.5 13.5V14.5C11.5 15.0523 11.9477 15.5 12.5 15.5H13.5C14.0523 15.5 14.5 15.0523 14.5 14.5V13.5C14.5 12.9477 14.0523 12.5 13.5 12.5H12.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2.5C1.72386 2.5 1.5 2.72385 1.5 3V16.5C1.5 16.7761 1.72386 17 2 17H16C16.2754 17 16.5 16.7757 16.5 16.4989V3.0011C16.5 2.72425 16.2754 2.5 16 2.5H14.5V1.5H16C16.8291 1.5 17.5 2.17335 17.5 3.0011V16.4989C17.5 17.3266 16.8291 18 16 18H2C1.17158 18 0.5 17.3285 0.5 16.5V3C0.5 2.17155 1.17158 1.5 2 1.5H4.5V2.5H2ZM12.5 2.5H6.5V1.5H12.5V2.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 7H1.5V6H16.5V7Z"
        fill="currentColor"
      />
      <path
        d="M4.5 0.5C4.5 0.22386 4.72385 0 5 0C5.27615 0 5.5 0.22386 5.5 0.5V4C5.5 4.27615 5.27615 4.5 5 4.5C4.72385 4.5 4.5 4.27615 4.5 4V0.5Z"
        fill="currentColor"
      />
      <path
        d="M12.5 0.5C12.5 0.22386 12.7239 0 13 0C13.2761 0 13.5 0.22386 13.5 0.5V4C13.5 4.27615 13.2761 4.5 13 4.5C12.7239 4.5 12.5 4.27615 12.5 4V0.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
