import * as React from 'react';
import { Toast } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

type ToastContextData = {
  add: (message: Omit<ToastMessage, 'id'> | string) => void;
};

const ToastContext = React.createContext({} as ToastContextData);

type ToastProviderProps = {
  children: React.ReactNode;
};

type ToastMessage = {
  id: string;
  title?: string;
  description: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  type?: React.ComponentProps<typeof Toast>['variant'];
};

export function ToastProvider({ children }: ToastProviderProps) {
  const { t } = useTranslate(['default']);

  const [toasts, setToasts] = React.useState<ToastMessage[]>([]);

  const add = React.useCallback(
    (message: Omit<ToastMessage, 'id' | string>) => {
      const toast =
        typeof message === 'string' ? { description: message } : message;

      setToasts(state => [
        ...state,
        { id: crypto.randomUUID(), ...toast } as ToastMessage,
      ]);
    },
    []
  );

  function handleToastOpenChange(open: boolean, toastId: string) {
    const isClosing = !open;

    if (isClosing) {
      setTimeout(
        () => setToasts(state => state.filter(item => item.id !== toastId)),
        500
      );
    }
  }

  const providerValue = React.useMemo(() => ({ add }), [add]);

  return (
    <Toast.Provider label={t('common.notification')}>
      <ToastContext.Provider value={providerValue}>
        {children}
      </ToastContext.Provider>

      {toasts.map(toast => (
        <Toast
          key={toast.id}
          title={toast.title || t(`toast.${toast.type || 'success'}`)}
          description={toast.description}
          variant={toast.type}
          onOpenChange={open => handleToastOpenChange(open, toast.id)}
          closeAriaLabel={t('common.close_toast')}
          icon={toast.icon}
          isDetailed
        >
          {toast.children}
        </Toast>
      ))}

      <Toast.DetailedViewport
        label={`${t('common.notifications')} ({hotkey})`}
      />
    </Toast.Provider>
  );
}

export function useToast() {
  const context = React.useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}
