type CircularNextArrowProps = {
  size?: number;
  color?: string;
};

export function CircularNextArrow({
  size = 20,
  color = 'white',
}: CircularNextArrowProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      color={color}
    >
      <path
        d="M10 5L9.10625 5.87062L12.5938 9.375H5V10.625H12.5938L9.10625 14.1081L10 15L15 10L10 5Z"
        fill="currentColor"
      />
      <path
        d="M10 18.75C8.26942 18.75 6.57769 18.2368 5.13876 17.2754C3.69983 16.3139 2.57832 14.9473 1.91606 13.3485C1.25379 11.7496 1.08051 9.9903 1.41813 8.29296C1.75575 6.59563 2.58911 5.03653 3.81282 3.81282C5.03653 2.58911 6.59563 1.75575 8.29296 1.41813C9.9903 1.08051 11.7496 1.25379 13.3485 1.91606C14.9473 2.57832 16.3139 3.69983 17.2754 5.13876C18.2368 6.57769 18.75 8.26942 18.75 10C18.7474 12.3198 17.8246 14.5439 16.1843 16.1843C14.5439 17.8246 12.3198 18.7474 10 18.75ZM10 2.5C8.51664 2.5 7.0666 2.93987 5.83323 3.76398C4.59986 4.58809 3.63856 5.75943 3.07091 7.12988C2.50325 8.50032 2.35473 10.0083 2.64411 11.4632C2.9335 12.918 3.64781 14.2544 4.6967 15.3033C5.7456 16.3522 7.08197 17.0665 8.53683 17.3559C9.99168 17.6453 11.4997 17.4968 12.8701 16.9291C14.2406 16.3614 15.4119 15.4001 16.236 14.1668C17.0601 12.9334 17.5 11.4834 17.5 10C17.4977 8.01159 16.7068 6.10528 15.3007 4.69926C13.8947 3.29324 11.9884 2.50232 10 2.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
