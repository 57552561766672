import { CSS, Text } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

type CharacterCountProps = {
  limit: number;
  current: number;
  css?: CSS;
};

export function CharacterCount({ current, limit, css }: CharacterCountProps) {
  const { t } = useTranslate('default');

  return (
    <Text
      css={{
        fontSize: '$0-5',
        fontWeight: '$medium',
        color: '#6F6F6F',
        ...css,
      }}
    >
      {t('common.amount_of_total', {
        amount: current,
        total: limit,
      })}
    </Text>
  );
}
