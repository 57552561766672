import React from 'react';

export const LcmIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_22743_33683)">
      <path
        d="M12.107 0.000451457C11.9779 0.00621203 11.8553 0.0583246 11.7615 0.147229C10.7234 1.2375 10.1132 2.66554 10.043 4.16934C10.1125 5.67391 10.7227 7.10285 11.7615 8.19349L12.2192 8.65216L12.9133 9.34222L15.5675 11.9964L12.9133 14.6517L13.3271 15.0655C14.5645 16.3492 15.2852 18.0439 15.3514 19.8256C15.35 20.2705 15.2991 20.7139 15.1995 21.1476L23.2427 13.1044C23.5363 12.8108 23.7012 12.4126 23.7012 11.9975C23.7012 11.5823 23.5363 11.1841 23.2427 10.8905L12.4984 0.147229C12.4463 0.0978395 12.3848 0.0594027 12.3176 0.0341919C12.2504 0.00898099 12.1788 -0.002492 12.107 0.000451457ZM8.99922 3.24324L1.10688 10.8905C0.813341 11.1841 0.648437 11.5823 0.648438 11.9975C0.648437 12.4126 0.813341 12.8108 1.10688 13.1044L11.8502 23.8497C11.948 23.9476 12.0808 24.0026 12.2192 24.0026C12.3575 24.0026 12.4903 23.9476 12.5881 23.8497C13.6265 22.7596 14.2367 21.3315 14.3067 19.8276C14.2364 18.3238 13.6262 16.8958 12.5881 15.8055L8.78212 11.9975L11.4363 9.34222L11.0225 8.92839C9.78539 7.6451 9.06499 5.95063 8.99922 4.16934C9.00026 3.72443 8.9 3.67695 8.99922 3.24324Z"
        fill="#325A6D"
      />
    </g>
    <defs>
      <clipPath id="clip0_22743_33683">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
