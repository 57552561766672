import { differenceInMonths, differenceInYears, isValid } from 'date-fns';

export function getYearsAndMonthsFromNow(date?: Date) {
  if (!date) {
    return { years: -1, months: -1 };
  }

  const now = new Date();

  const years = Math.max(differenceInYears(now, date), 0);
  const months = Math.max(differenceInMonths(now, date) - years * 12, 0);

  return { years, months };
}

export function formatDate(
  locale: string,
  date: Date,
  options?: Intl.DateTimeFormatOptions
) {
  if (!isValid(date)) return '';

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return new Intl.DateTimeFormat(locale, {
    timeZone,
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    ...options,
  }).format(date);
}
