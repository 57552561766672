type PdfProps = {
  size?: number;
  color?: string;
};

export function Pdf({ size = 24, color = '#B30B00' }: PdfProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      color={color}
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_19420_6530)">
        <path
          d="M22.5 13.5V12H18V19.5H19.5V16.5H21.75V15H19.5V13.5H22.5Z"
          fill="currentColor"
        />
        <path
          d="M14.25 19.5H11.25V12H14.25C14.8465 12.0007 15.4185 12.2379 15.8403 12.6597C16.2621 13.0815 16.4993 13.6535 16.5 14.25V17.25C16.4993 17.8465 16.2621 18.4185 15.8403 18.8403C15.4185 19.2621 14.8465 19.4993 14.25 19.5ZM12.75 18H14.25C14.4488 17.9998 14.6395 17.9207 14.7801 17.7801C14.9207 17.6395 14.9998 17.4488 15 17.25V14.25C14.9998 14.0512 14.9207 13.8605 14.7801 13.7199C14.6395 13.5793 14.4488 13.5002 14.25 13.5H12.75V18Z"
          fill="currentColor"
        />
        <path
          d="M8.25 12H4.5V19.5H6V17.25H8.25C8.64766 17.2495 9.02888 17.0913 9.31007 16.8101C9.59126 16.5289 9.74946 16.1477 9.75 15.75V13.5C9.74954 13.1023 9.59136 12.721 9.31016 12.4398C9.02895 12.1586 8.64768 12.0005 8.25 12ZM6 15.75V13.5H8.25L8.25075 15.75H6Z"
          fill="currentColor"
        />
        <path
          d="M16.5 10.5V7.50001C16.5027 7.40144 16.484 7.30346 16.4451 7.21282C16.4063 7.12218 16.3482 7.04105 16.275 6.97501L11.025 1.72501C10.959 1.65176 10.8778 1.59371 10.7872 1.55486C10.6966 1.51601 10.5986 1.4973 10.5 1.50001H3C2.60253 1.50118 2.22168 1.65959 1.94063 1.94064C1.65958 2.22169 1.50117 2.60255 1.5 3.00001V21C1.5 21.3978 1.65804 21.7794 1.93934 22.0607C2.22064 22.342 2.60218 22.5 3 22.5H15V21H3V3.00001H9V7.50001C9.00117 7.89748 9.15958 8.27833 9.44063 8.55938C9.72168 8.84044 10.1025 8.99885 10.5 9.00001H15V10.5H16.5ZM10.5 7.50001V3.30001L14.7 7.50001H10.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_19420_6530">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
