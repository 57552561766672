import { IoWarningOutline } from 'react-icons/io5';
import { styled, Text, Tooltip } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { useClickableTooltip } from '~/shared/hooks';

import { ClickableTooltipTrigger } from '../ClickableTooltipTrigger';

export enum AttentionSeverity {
  WARNING = 'warning',
  ERROR = 'error',
  DEFAULT = 'default',
}

type AttentionTooltipProps = {
  message: string;
  severity?: AttentionSeverity;
  children?: React.ReactElement;
};

const StyledTitle = styled(Text, {
  color: '$white',
  fontWeight: '$medium',
  fontSize: '$md',
  lineHeight: '$4',
});

const StyledText = styled('div', {
  color: '$white',
  fontWeight: '$normal',
  fontSize: '$sm',
  lineHeight: '$4',
  wordBreak: 'break-all',
});

const StyledContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '270px',
  gap: '$2',
});

export function AttentionTooltip({
  message,
  severity = AttentionSeverity.DEFAULT,
  children,
}: AttentionTooltipProps) {
  const { t } = useTranslate('default');
  const { rootTooltipProps, openTooltip } = useClickableTooltip();

  return (
    <Tooltip
      {...rootTooltipProps}
      side="top"
      align="start"
      alignOffset={-16}
      content={
        <StyledContent>
          <StyledTitle>{t('attention_tooltip.title')}</StyledTitle>
          <StyledText dangerouslySetInnerHTML={{ __html: message }} />
        </StyledContent>
      }
      showArrow
    >
      <ClickableTooltipTrigger
        onClick={openTooltip}
        onTouchEnd={e => e.stopPropagation()}
      >
        {children || (
          <IoWarningOutline
            size={16}
            color={
              severity === AttentionSeverity.WARNING ? '#ED5F00' : '#DC3D43'
            }
          />
        )}
      </ClickableTooltipTrigger>
    </Tooltip>
  );
}
