export const REGISTERED_LANGUAGES = [
  'de-DE',
  'en-US',
  'es',
  'fr-CA',
  'fr-FR',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'nl-NL',
  'pt-BR',
  'ru-RU',
  'uk-UA',
  'vi-VN',
  'zh-CN',
] as const;

export const DEFAULT_LANGUAGE = 'en-US';

export type Language = (typeof REGISTERED_LANGUAGES)[number];
