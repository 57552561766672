import { DefaultParamType, TFnType, TranslationKey } from '@tolgee/react';
import { SurveyStatusEnum } from 'abi-lcm-common-types';

import { NOMINATION_STATUS_CONFIG_STYLE } from '../constants/nominations_status';

export function useTranslateNominationStatus(
  t: TFnType<DefaultParamType, string, TranslationKey>
) {
  function translateStatus(key: SurveyStatusEnum) {
    const { label } = NOMINATION_STATUS_CONFIG_STYLE[key];

    return t(`common.nomination_status.${label}`, { ns: 'default' });
  }

  return { translateStatus };
}
