import * as React from 'react';
import { useTimeout } from 'react-use';

type ClientOnlyProps = {
  children: React.ReactNode;
  placeholder?: React.ReactNode;
};

// This component should be used in cases where Next.js fails to hydrate the UI
export function ClientOnly({ children, placeholder = null }: ClientOnlyProps) {
  const [shouldRender] = useTimeout(1);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{shouldRender() ? children : placeholder}</>;
}
