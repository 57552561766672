import { RiErrorWarningFill } from 'react-icons/ri';
import { CSS, Flex, Text, theme } from '@ghq-abi/design-system';

type InputErrorMessageProps = {
  message: string;
  visible?: boolean;
  css?: CSS;
};

export function InputErrorMessage({
  message,
  visible = true,
  css,
}: InputErrorMessageProps) {
  if (!visible) return null;

  return (
    <Flex gap="xs">
      <RiErrorWarningFill size={18} color={theme.colors.red700.value} />
      <Text
        css={{
          fontSize: '$2',
          fontWeight: '$bold',
          color: '$red700',
          ...css,
        }}
      >
        {message}
      </Text>
    </Flex>
  );
}
