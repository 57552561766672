import React from 'react';

type ShareIconProps = {
  size?: number;
  color?: string;
};

export const ShareIcon: React.FC<ShareIconProps> = ({
  color = '#325A6D',
  size = 14,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M11.8125 0.875C11.0876 0.875 10.5 1.46263 10.5 2.1875C10.5 2.91237 11.0876 3.5 11.8125 3.5C12.5374 3.5 13.125 2.91237 13.125 2.1875C13.125 1.46263 12.5374 0.875 11.8125 0.875ZM9.625 2.1875C9.625 0.979377 10.6044 0 11.8125 0C13.0206 0 14 0.979377 14 2.1875C14 3.39562 13.0206 4.375 11.8125 4.375C11.1484 4.375 10.5535 4.07909 10.1523 3.61193L4.27436 6.34218C4.33974 6.5498 4.375 6.77078 4.375 7C4.375 7.22922 4.33975 7.4502 4.27436 7.65782L10.1523 10.3881C10.5535 9.92091 11.1484 9.625 11.8125 9.625C13.0206 9.625 14 10.6044 14 11.8125C14 13.0206 13.0206 14 11.8125 14C10.6044 14 9.625 13.0206 9.625 11.8125C9.625 11.5833 9.66026 11.3623 9.72564 11.1547L3.84771 8.42443C3.44653 8.89159 2.85157 9.1875 2.1875 9.1875C0.979377 9.1875 0 8.20812 0 7C0 5.79188 0.979377 4.8125 2.1875 4.8125C2.85157 4.8125 3.44653 5.10841 3.84771 5.57557L9.72564 2.84532C9.66026 2.6377 9.625 2.41672 9.625 2.1875ZM2.1875 5.6875C1.46263 5.6875 0.875 6.27513 0.875 7C0.875 7.72487 1.46263 8.3125 2.1875 8.3125C2.91237 8.3125 3.5 7.72487 3.5 7C3.5 6.27513 2.91237 5.6875 2.1875 5.6875ZM11.8125 10.5C11.0876 10.5 10.5 11.0876 10.5 11.8125C10.5 12.5374 11.0876 13.125 11.8125 13.125C12.5374 13.125 13.125 12.5374 13.125 11.8125C13.125 11.0876 12.5374 10.5 11.8125 10.5Z"
      fill={color}
    />
  </svg>
);
