/* eslint-disable @next/next/no-before-interactive-script-outside-document */
// Disabled this warning, as it is being imported on _document.tsx
import Script from 'next/script';

import { getEnv } from '../utils/environments';

const appEnv = getEnv();

function checkScriptAccess() {
  return !['production'].includes(appEnv);
}

export function OneTrustScript() {
  if (checkScriptAccess()) return null;

  return (
    <Script
      strategy="beforeInteractive"
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      data-document-language="true"
      type="text/javascript"
      data-domain-script="496a8a00-fec0-4b4b-91c6-74ebd55bea82"
    />
  );
}

export function OneTrustStartScript() {
  if (checkScriptAccess()) return null;

  return (
    <Script id="optanon-init" strategy="beforeInteractive">
      {`
        function OptanonWrapper() { }
      `}
    </Script>
  );
}
