import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { HomePageApiResponse } from 'abi-lcm-common-types';

import { useToast } from '~/app/contexts/ToastContext';
import { lcmApi } from '~/shared/services/api';

import { GET_PROCESS_INFO_QUERY_KEY } from './queryKeys';

async function getProcessInfo(): Promise<HomePageApiResponse> {
  const response = await lcmApi.get<HomePageApiResponse>('/home-page/info');

  return response.data;
}

export function useGetProcessInfo() {
  const { t } = useTranslate('home');
  const toast = useToast();

  return useInfiniteQuery({
    queryKey: [GET_PROCESS_INFO_QUERY_KEY],
    queryFn: getProcessInfo,
    onError: () => {
      toast.add({
        type: 'error',
        description: t('home-page.error-get-process-info'),
      });
    },
  });
}
