import * as React from 'react';
import { ProfileDropdown } from '@ghq-abi/design-system';

import type { User } from '~/shared/auth/useUser';

import { useUserDropdown } from './hooks';

type HeaderProps = { user: User };

export function UserDropdown({ user }: HeaderProps) {
  const { items, profileInfo } = useUserDropdown({ user });

  return <ProfileDropdown items={items} profileInfo={profileInfo} />;
}
