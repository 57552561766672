type AuthResponse = {
  token: string;
  refreshToken: string;
};

export class AuthService {
  static async getEmployeeAuth(
    employeeId?: string | null
  ): Promise<AuthResponse> {
    const response = await fetch(`/api/${employeeId}/auth`);

    return response.json();
  }
}
