import { CSS } from '@ghq-abi/design-system';

import { DetailSpan, SquareIcon, StatusBox, TextSpan } from './styles';

type StatusTextProps = React.ComponentProps<typeof StatusBox> &
  React.ComponentPropsWithoutRef<'button'> & {
    detail?: number | string;
    text?: string;
    squareColor?: string;
    as?: 'button' | 'div';
    active?: boolean;
    textCss?: CSS;
    squareCss?: CSS;
  };

export function StatusText({
  as = 'div',
  text,
  detail,
  squareColor,
  textCss,
  squareCss,
  ...props
}: StatusTextProps) {
  return (
    <StatusBox as={as} isButton={as === 'button'} {...props}>
      <SquareIcon css={{ backgroundColor: squareColor, ...squareCss }} />
      {(detail || detail === 0) && <DetailSpan>{detail}</DetailSpan>}
      <TextSpan css={textCss}>{text}</TextSpan>
    </StatusBox>
  );
}
