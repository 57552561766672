import { useCallback } from 'react';

export function useSortArray<T>(
  arr: T[],
  key: keyof T | null,
  direction: 'asc' | 'desc'
) {
  const sortRaters = useCallback(() => {
    if (!key) return arr;

    return [...arr].sort((a, b) => {
      const valueA = a[key];
      const valueB = b[key];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        // For string values, use localeCompare for proper string sorting
        return direction === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      // For numerical values, do numeric sorting
      return direction === 'asc'
        ? Number(valueA) - Number(valueB)
        : Number(valueB) - Number(valueA);
    });
  }, [arr, key, direction]);

  const sortedArray = sortRaters();

  return [sortedArray];
}
