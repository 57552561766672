import { Box, CSS, Flex, styled } from '@ghq-abi/design-system';

export const StyledWrapper = styled(Flex, {
  pt: '$appHeaderHeight',
  minHeight: '100vh',

  variants: {
    fullscreen: { true: { pt: 0 } },
  },
});

const fullscreenStyles: CSS = {
  p: 0,
};

export const StyledContent = styled(Box, {
  flex: 1,
  maxWidth: '$full',
  p: '$md',
  display: 'flex',
  flexDirection: 'column',

  '@tabletSm': {
    p: '$8 $lg $lg calc($appMenuClosedWidth + $lg)',
  },

  variants: {
    fullscreen: {
      true: fullscreenStyles,
    },
  },

  compoundVariants: [
    {
      fullscreen: true,
      css: fullscreenStyles,
    },
  ],
});
