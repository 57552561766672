import { forwardRef } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { MdCheck } from 'react-icons/md';
import Select, { components } from 'react-select';
import {
  Box,
  CSS,
  Flex,
  reusables,
  selectClassNames,
  VariantProps,
} from '@ghq-abi/design-system';

import { MultiSelectOptionModel } from '../../types/MultiSelectOption';

import {
  StyledCheck,
  StyledCheckbox,
  StyledMultipleSelect,
  StyledSelectOption,
} from './styles';

type MultipleSelectProps = VariantProps<typeof StyledMultipleSelect> &
  React.ComponentProps<Select> & {
    showCheckbox?: boolean;
    css?: CSS;
    isClearableItems?: boolean;
    value?: MultiSelectOptionModel[];
  };

type MultipleSelectForwardRef = React.ElementRef<typeof Select>;

function DropdownIndicator() {
  return <FaChevronDown size={14} />;
}

function ClearIndicator(
  props: React.ComponentProps<typeof components.ClearIndicator>
) {
  return (
    <components.ClearIndicator {...props}>
      <FiX size={16} className={selectClassNames.clearIndicator} />
    </components.ClearIndicator>
  );
}

const OptionWithCheckbox = ({
  children,
  ...props
}: React.ComponentProps<typeof components.Option> & {
  showCheckbox?: boolean;
}) => {
  //! USE THIS TO GAIN PERFORMANCE IF NEEDED
  // eslint-disable-next-line no-unused-vars
  // const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  // const newProps = { ...props, innerProps: rest };

  return (
    <StyledSelectOption
      active={props.isSelected}
      className={[
        props.className,
        props.isSelected ? 'is-selected' : '',
        props.isFocused ? 'is-focused' : '',
      ]
        .filter(Boolean)
        .join(' ')}
      {...props}
    >
      <Flex align="center" css={{ gap: '$3-5' }}>
        <Flex
          align="center"
          justify="center"
          css={{ display: props.showCheckbox ? 'flex' : 'none' }}
        >
          <StyledCheck active={props.isSelected}>
            <StyledCheckbox
              size="md"
              type="checkbox"
              checked={props.isSelected}
              onChange={() => props.selectOption(props.data)}
            />
            <MdCheck />
          </StyledCheck>
        </Flex>

        <Box>{children}</Box>
      </Flex>
    </StyledSelectOption>
  );
};

export const MultiSelect = forwardRef<
  MultipleSelectForwardRef,
  MultipleSelectProps
>(function CustomSelect(props, ref) {
  const {
    isClearableItems,
    isDisabled,
    isMulti = true,
    isSearchable = false,
    closeMenuOnSelect = false,
    showCheckbox,
    hideSelectedOptions = false,
    css,
    value,
    ...rest
  } = props;

  return (
    <StyledMultipleSelect
      isClearableItems={isClearableItems}
      disabled={isDisabled}
      css={css}
    >
      <Select
        ref={ref}
        value={value}
        isMulti={isMulti}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        components={{
          Option: optionProps =>
            OptionWithCheckbox({
              ...optionProps,
              showCheckbox,
            }),
          DropdownIndicator,
          ClearIndicator,
        }}
        classNames={{
          clearIndicator: () => selectClassNames.clearIndicator,
          container: () => selectClassNames.container,
          control: () => selectClassNames.control,
          dropdownIndicator: () => selectClassNames.dropdownIndicator,
          group: () => selectClassNames.group,
          groupHeading: () => selectClassNames.groupHeading,
          indicatorsContainer: () => selectClassNames.indicatorsContainer,
          indicatorSeparator: () => selectClassNames.indicatorSeparator,
          input: () => selectClassNames.input,
          loadingIndicator: () => selectClassNames.loadingIndicator,
          loadingMessage: () => selectClassNames.loadingMessage,
          menu: () => selectClassNames.menu,
          menuList: () =>
            `${selectClassNames.menuList} ${reusables.scrollbar()}`,
          menuPortal: () => selectClassNames.menuPortal,
          multiValue: () => selectClassNames.multiValue,
          multiValueLabel: () => selectClassNames.multiValueLabel,
          multiValueRemove: () => selectClassNames.multiValueRemove,
          noOptionsMessage: () => selectClassNames.noOptionsMessage,
          option: () => selectClassNames.option,
          placeholder: () => selectClassNames.placeholder,
          singleValue: () => selectClassNames.singleValue,
          valueContainer: () =>
            `${selectClassNames.valueContainer} ${reusables.scrollbar()}`,
        }}
        {...rest}
      />
    </StyledMultipleSelect>
  );
});
