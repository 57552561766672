import { useMutation } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';

import { useToast } from '~/app/contexts/ToastContext';
import { lcmApi } from '~/shared/services/api';

function updateLanguage(languageId: string) {
  return lcmApi.patch(`/employees/selected-language/${languageId}`);
}

export function useUpdateLanguage() {
  const { t } = useTranslate('web-app');
  const toast = useToast();

  return useMutation({
    mutationFn: updateLanguage,
    onSuccess: () => {
      toast.add({
        type: 'success',
        description: t('language.success-update-language'),
      });

      window.location.reload();
    },
    onError: async () => {
      toast.add({
        type: 'error',
        description: t('language.error-get-available-languages'),
      });
    },
  });
}
