import { CSS, Text } from '@ghq-abi/design-system';

type PositionAndZoneProps = {
  zoneAcronym?: string;
  position?: string;
  css?: CSS;
};
export function PositionAndZone({
  position,
  zoneAcronym,
  css,
}: PositionAndZoneProps) {
  return (
    <Text
      css={{
        display: 'block',
        fontSize: '$xs',
        color: '$gray750',
        fontWeight: '$normal',
        ...css,
      }}
    >
      {[position, zoneAcronym].filter(Boolean).join(' - ')}
    </Text>
  );
}
