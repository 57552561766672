import { useQuery } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { GetEmployeeTasksApiResponse } from 'abi-lcm-common-types';

import { useToast } from '~/app/contexts/ToastContext';
import { lcmApi } from '~/shared/services/api';

import { GET_TASKS_QUERY_KEY } from './queryKeys';

async function getTasks(): Promise<GetEmployeeTasksApiResponse> {
  const response = await lcmApi.get<GetEmployeeTasksApiResponse>(
    'task-completion'
  );

  return response.data;
}

export function useGetTasks() {
  const { t } = useTranslate('home');
  const toast = useToast();

  return useQuery({
    queryKey: [GET_TASKS_QUERY_KEY],
    queryFn: getTasks,
    onError: () => {
      toast.add({
        type: 'error',
        description: t('home-page.error-get-tasks'),
      });
    },
  });
}
