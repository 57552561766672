import { CSS, Text } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';
import { NominatedAssignmentEnum } from 'abi-lcm-common-types';

type RaterRelationshipProps = {
  nominatedAssignment?: NominatedAssignmentEnum;
  css?: CSS;
};

export function RaterRelationship({
  nominatedAssignment,
  css,
}: RaterRelationshipProps) {
  const { t } = useTranslate('default');
  return (
    <Text
      css={{
        display: 'block',
        fontSize: '$xs',
        color: '$gray750',
        fontWeight: '$normal',
        ...css,
      }}
    >
      {`${t('rater_relationship.title')}: `}
      <Text as="span" css={{ fontSize: '$xs', ...css, fontWeight: 'bold' }}>
        {t(`rater_relationship.${nominatedAssignment}`)}
      </Text>
    </Text>
  );
}
