import * as React from 'react';
import { Flex, Label, Switch } from '@ghq-abi/design-system';

import { InfoCircleFilled } from '../icons';
import { QuestionTooltip } from '../QuestionTooltip';

type SwitchFilterProps = React.ComponentProps<typeof Switch> & {
  label: string;
  tooltipContent: string;
};

export function SwitchFilter({
  label,
  tooltipContent,
  ...props
}: SwitchFilterProps) {
  const id = React.useId();

  return (
    <Flex align="center">
      <Switch
        id={id}
        css={{ width: '$8', height: '$5' }}
        thumbProps={{
          css: { width: '$4', height: '$4' },
        }}
        {...props}
      />
      <Label
        htmlFor={id}
        css={{
          color: '$gray780',
          fontSize: '$sm',
          cursor: 'pointer',
          pl: '$sm',
        }}
      >
        {label}
      </Label>
      <QuestionTooltip
        content={tooltipContent}
        buttonCss={{ ml: '$sm' }}
        iconSize={16}
        icon={<InfoCircleFilled />}
      />
    </Flex>
  );
}
