import * as React from 'react';
import { useQueryClient } from '@tanstack/react-query';

export function useGetRelatedQueries() {
  const queryClient = useQueryClient();

  const getRelatedQueries = React.useCallback(
    (queryKey: unknown[]) =>
      queryClient
        .getQueryCache()
        .getAll()
        .filter(cache =>
          queryKey.every((key, index) => key === cache.queryKey[index])
        ),
    [queryClient]
  );

  return { getRelatedQueries };
}
