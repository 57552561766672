export function useCleanUrl() {
  const isURL = (str: string) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return urlRegex.test(str);
  };

  const cleanUrl = (url: string) => {
    const cleanedUrl = url.replace(/[^\x20-\x7E]/g, '');
    return isURL(cleanedUrl) ? cleanedUrl : '#';
  };

  return {
    cleanUrl,
  };
}
