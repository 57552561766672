import { styled } from '@ghq-abi/design-system';

export const FilterInputWrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  gap: '$xs',
  flex: 1,
  minWidth: '$72',

  '@lg': {
    minWidth: 'calc(20% - $4)',
  },
});

export const FilterBoxContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$gray100',
  borderRadius: '$3',
  padding: '$3',
  gap: '$sm',
  my: '$md',
});
