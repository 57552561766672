import * as React from 'react';
import { AiFillExclamationCircle } from 'react-icons/ai';
import { CSS, Text } from '@ghq-abi/design-system';

type ErrorTextProps = {
  children: React.ReactNode;
  css?: CSS;
  iconSize?: number;
  hasIcon?: boolean;
};

export function ErrorText({
  children,
  css,
  iconSize = 24,
  hasIcon = true,
}: ErrorTextProps) {
  return (
    <Text
      css={{
        display: 'flex',
        alignItems: 'center',
        gap: '$sm',
        color: '$gray780',
        ...css,
      }}
    >
      {hasIcon && <AiFillExclamationCircle size={iconSize} />}
      <Text as="span" css={{ flex: 1, fontSize: 'inherit', color: 'inherit' }}>
        {children}
      </Text>
    </Text>
  );
}
