import * as React from 'react';

type EditBoxProps = {
  size?: number;
  color?: string;
};

export function EditBox({ size = 16, color = '$gray575' }: EditBoxProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      color={color}
    >
      <g clipPath="url(#clip0_17053_31141)">
        <path
          d="M11.175 10.4695L12.0639 9.58059C12.2028 9.4417 12.4444 9.53893 12.4444 9.73893V13.7778C12.4444 14.5139 11.8472 15.1111 11.1111 15.1111H1.33333C0.597222 15.1111 0 14.5139 0 13.7778V4.00004C0 3.26392 0.597222 2.6667 1.33333 2.6667H8.93056C9.12778 2.6667 9.22778 2.90559 9.08889 3.04726L8.2 3.93615C8.15833 3.97781 8.10278 4.00004 8.04167 4.00004H1.33333V13.7778H11.1111V10.625C11.1111 10.5667 11.1333 10.5111 11.175 10.4695ZM15.525 4.86392L8.23056 12.1584L5.71944 12.4361C4.99167 12.5167 4.37222 11.9028 4.45278 11.1695L4.73056 8.65837L12.025 1.36392C12.6611 0.727813 13.6889 0.727813 14.3222 1.36392L15.5222 2.56392C16.1583 3.20004 16.1583 4.23059 15.525 4.86392ZM12.7806 5.72226L11.1667 4.10837L6.00556 9.27226L5.80278 11.0861L7.61667 10.8834L12.7806 5.72226ZM14.5806 3.50837L13.3806 2.30837C13.2667 2.19448 13.0806 2.19448 12.9694 2.30837L12.1111 3.1667L13.725 4.78059L14.5833 3.92226C14.6944 3.80559 14.6944 3.62226 14.5806 3.50837Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_17053_31141">
          <rect
            width="16"
            height="14.2222"
            fill="white"
            transform="translate(0 0.888916)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
