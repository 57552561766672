import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslate } from '@tolgee/react';
import { GetAllLanguagesResponse } from 'abi-lcm-common-types';

import { useGetAvailableLanguages, useUpdateLanguage } from '../api';
import { LanguageDropdownItem } from '../types';

export function useLanguageDropdown(currentLanguage?: string) {
  const { t } = useTranslate('web-app');

  const languages = useGetAvailableLanguages();
  const [selectedLanguageCode, setSelectedLanguageCode] =
    useState(currentLanguage);
  const updateLanguage = useUpdateLanguage();

  const handleLanguageChange = (language: LanguageDropdownItem) => {
    setSelectedLanguageCode(language.code);

    updateLanguage.mutate(language.id, {
      onError() {
        setSelectedLanguageCode(currentLanguage);
      },
    });
  };

  const formatTranslation = useCallback(
    (language: GetAllLanguagesResponse[number]) => {
      return `${t(language.nameKey)} ${
        language.variantKey ? t(language.variantKey || '') : ''
      }`;
    },
    [t]
  );

  const availableLanguages = useMemo(() => {
    const newLanguages: LanguageDropdownItem[] =
      languages.data?.pages[0]?.map(language => {
        return {
          id: language.id,
          code: language.code,
          label: formatTranslation(language),
        };
      }) || [];

    return newLanguages.sort((a, b) => a.label.localeCompare(b.label));
  }, [formatTranslation, languages.data?.pages]);

  const selectedLanguageLabel = useMemo(() => {
    const languageData = availableLanguages.find(
      lang => lang.code === selectedLanguageCode
    );

    return languageData?.label || '';
  }, [availableLanguages, selectedLanguageCode]);

  useEffect(() => {
    setSelectedLanguageCode(currentLanguage);
  }, [currentLanguage]);

  return {
    selectedLanguageLabel,
    availableLanguages,
    isLoadingLanguages: languages.isLoading,
    handleLanguageChange,
  };
}
