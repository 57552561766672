import { ReactNode } from 'react';
import { CSS, Flex } from '@ghq-abi/design-system';

type ContentRootProps = {
  children: ReactNode;
  css?: CSS;
};

export function ContentRoot({ children, css }: ContentRootProps) {
  return (
    <Flex direction="column" css={{ gap: '$xs', ...css }}>
      {children}
    </Flex>
  );
}
