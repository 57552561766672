import React from 'react';
import { useDrag } from 'react-dnd';
import { Box } from '@ghq-abi/design-system';

import { DraggableProps } from './types';

export function Draggable<T>({ item, children }: DraggableProps<T>) {
  const [{ isDragging }, dragRef] = useDrag({
    type: item.type,
    item,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <Box
      ref={dragRef}
      css={{
        cursor: isDragging ? 'grabbing' : 'grab',
      }}
    >
      {children}
    </Box>
  );
}
