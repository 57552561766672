import React from 'react';

export const OurAppsIcon: React.FC = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.13461 3.14423C2.69221 3.14423 3.14423 2.69221 3.14423 2.13461C3.14423 1.57702 2.69221 1.125 2.13461 1.125C1.57702 1.125 1.125 1.57702 1.125 2.13461C1.125 2.69221 1.57702 3.14423 2.13461 3.14423ZM9.00107 3.14423C9.55866 3.14423 10.0107 2.69221 10.0107 2.13461C10.0107 1.57702 9.55866 1.125 9.00107 1.125C8.44347 1.125 7.99146 1.57702 7.99146 2.13461C7.99146 2.69221 8.44347 3.14423 9.00107 3.14423ZM16.8771 2.13461C16.8771 2.69221 16.4251 3.14423 15.8675 3.14423C15.3099 3.14423 14.8579 2.69221 14.8579 2.13461C14.8579 1.57702 15.3099 1.125 15.8675 1.125C16.4251 1.125 16.8771 1.57702 16.8771 2.13461ZM2.13461 10.009C2.69221 10.009 3.14423 9.55702 3.14423 8.99942C3.14423 8.44183 2.69221 7.98981 2.13461 7.98981C1.57702 7.98981 1.125 8.44183 1.125 8.99942C1.125 9.55702 1.57702 10.009 2.13461 10.009ZM10.0107 8.99942C10.0107 9.55702 9.55866 10.009 9.00107 10.009C8.44347 10.009 7.99146 9.55702 7.99146 8.99942C7.99146 8.44183 8.44347 7.98981 9.00107 7.98981C9.55866 7.98981 10.0107 8.44183 10.0107 8.99942ZM15.8675 10.009C16.4251 10.009 16.8771 9.55702 16.8771 8.99942C16.8771 8.44183 16.4251 7.98981 15.8675 7.98981C15.3099 7.98981 14.8579 8.44183 14.8579 8.99942C14.8579 9.55702 15.3099 10.009 15.8675 10.009ZM3.14423 15.8659C3.14423 16.4235 2.69221 16.8755 2.13461 16.8755C1.57702 16.8755 1.125 16.4235 1.125 15.8659C1.125 15.3083 1.57702 14.8563 2.13461 14.8563C2.69221 14.8563 3.14423 15.3083 3.14423 15.8659ZM9.00107 16.8755C9.55866 16.8755 10.0107 16.4235 10.0107 15.8659C10.0107 15.3083 9.55866 14.8563 9.00107 14.8563C8.44347 14.8563 7.99146 15.3083 7.99146 15.8659C7.99146 16.4235 8.44347 16.8755 9.00107 16.8755ZM16.8771 15.8659C16.8771 16.4235 16.4251 16.8755 15.8675 16.8755C15.3099 16.8755 14.8579 16.4235 14.8579 15.8659C14.8579 15.3083 15.3099 14.8563 15.8675 14.8563C16.4251 14.8563 16.8771 15.3083 16.8771 15.8659Z"
      fill="#191F2E"
    />
  </svg>
);
