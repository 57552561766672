import { Box, styled, Text } from '@ghq-abi/design-system';

export const EmployeeCardContainer = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '$default',
  gap: '$sm',
  p: '$sm',
  cursor: 'pointer',
  border: 0,
  bg: '$gray300',
  textAlign: 'left',
  transition: '$quickly-ease',
  '&:hover': { filter: 'brightness(0.92 )' },
  '&:active': { filter: 'brightness(0.92)' },

  variants: {
    active: {
      true: {
        filter: 'brightness(0.90)',
      },
    },
    surveyStatus: {
      PENDING_MANAGERS_APPROVAL: {
        bg: '#FFFCE8',
      },
      SUBMITTED: {
        bg: '#EEFFF4',
      },
      FEEDBACK_GIVEN: {
        bg: '#F7CFB4',
      },
      NOT_SUBMITTED: {
        bg: '$gray300',
      },
    },
  },
});

export const StatusCircle = styled('div', {
  borderRadius: '$circle',
  width: '$2-5',
  height: '$2-5',
});

export const StatusText = styled(Text, {
  fontSize: '$1',
  fontWeight: '$medium',
  lineHeight: '$4',
  color: '#191F2E',
});

export const NominationDetailsContainer = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: '$full',
  overflowY: 'auto',
  gap: '$lg',
  p: '0 !important',
});

export const MobileDetailsBox = styled(Box, {
  width: '$full',
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  border: '1px solid #E2E4E8',
  borderRadius: '$4',
  backgroundColor: '#FFFFFF',
  p: '$4 $2',
});

export const EmployeeList = styled('div', {
  width: '$full',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #E2E4E8',
  borderRadius: '$4',
  backgroundColor: '#FFFFFF',
  p: '$4 $2',
  gap: '$sm',

  '@tabletSm': {
    gap: '$md',
    width: '400px',
    border: 'none',
    p: 0,
  },
});

export const MobileDetailsName = styled(Text, {
  fontSize: '$2',
  fontWeight: '$normal',
  lineHeight: '$6',
  color: '#191F2E',
});

export const MobileDetailsPosition = styled(Text, {
  fontSize: '$1',
  fontWeight: '$normal',
  lineHeight: '$4',
  color: '#191F2E',
});

export const MobileDetailsSurveyType = styled(Text, {
  fontSize: '$1',
  fontWeight: '$normal',
  lineHeight: '$4',
  color: '#191F2E',
});
