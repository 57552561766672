export function useNProgressBarVisibility() {
  function setNProgressBarVisibility(value: 'initial' | 'none') {
    const ID_OF_NEXT_PROGRESS_BAR = 'nprogress';

    const nProgressBar = window.document.getElementById(
      ID_OF_NEXT_PROGRESS_BAR
    );

    nProgressBar?.style.setProperty('display', value);
  }

  return { setNProgressBarVisibility };
}
