import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import {
  GetEmployeeTasksApiResponse,
  TaskTypeEnum,
} from 'abi-lcm-common-types';

import { useToast } from '~/app/contexts/ToastContext';
import { lcmApi } from '~/shared/services/api';

import { GET_TASKS_QUERY_KEY } from './queryKeys';

type ChangeTaskCompletionPayload = {
  taskType: TaskTypeEnum;
  newCompletion: boolean;
};

const updateTasks = (
  task: ChangeTaskCompletionPayload,
  old?: GetEmployeeTasksApiResponse
) => {
  if (!old) return old;

  return old.map(oldTask => {
    return oldTask.taskType === task.taskType
      ? { ...oldTask, isCompleted: task.newCompletion }
      : oldTask;
  });
};

async function changeTaskCompletion(
  payload: ChangeTaskCompletionPayload
): Promise<void> {
  const { data } = await lcmApi.patch(
    '/task-completion/change-completion',
    payload
  );

  return data;
}

export function useTaskCompletion() {
  const { t } = useTranslate('home');
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: changeTaskCompletion,
    onMutate: async payload => {
      await queryClient.cancelQueries({ queryKey: [GET_TASKS_QUERY_KEY] });

      const previous = queryClient.getQueryData([GET_TASKS_QUERY_KEY]);

      queryClient.setQueryData<GetEmployeeTasksApiResponse>(
        [GET_TASKS_QUERY_KEY],
        old => updateTasks(payload, old)
      );

      return { optimistic: payload, previous };
    },
    onError: async (_error, _payload, context) => {
      toast.add({
        type: 'error',
        description: t('home-page.error-change-completion-tasks'),
      });

      queryClient.setQueryData([GET_TASKS_QUERY_KEY], context?.previous);
    },
  });
}
