type FeedBackProps = {
  size?: number;
  color?: string;
};

export function Feedback({ color = '#ED5F00', size = 20 }: FeedBackProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      color={color}
    >
      <g clipPath="url(#clip0_14449_64877)">
        <path
          d="M16.6665 1.66663H3.34147C2.4248 1.66663 1.6748 2.41663 1.6748 3.33329V18.3333L4.9998 15H16.6665C17.5831 15 18.3331 14.25 18.3331 13.3333V3.33329C18.3331 2.41663 17.5831 1.66663 16.6665 1.66663ZM10.8331 11.6666H9.16647V9.99996H10.8331V11.6666ZM10.8331 7.49996C10.8331 7.95829 10.4581 8.33329 9.9998 8.33329C9.54147 8.33329 9.16647 7.95829 9.16647 7.49996V5.83329C9.16647 5.37496 9.54147 4.99996 9.9998 4.99996C10.4581 4.99996 10.8331 5.37496 10.8331 5.83329V7.49996Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_14449_64877">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
