type PdfFileProps = {
  size?: number;
  color?: string;
};

export function PdfFile({ size = 14, color = '#325A6D' }: PdfFileProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.25 3.9375V12.25C12.25 13.2165 11.4665 14 10.5 14H9.625V13.125H10.5C10.9832 13.125 11.375 12.7332 11.375 12.25V3.9375H9.625C8.90013 3.9375 8.3125 3.34987 8.3125 2.625V0.875H3.5C3.01675 0.875 2.625 1.26675 2.625 1.75V9.625H1.75V1.75C1.75 0.783501 2.5335 0 3.5 0H8.3125L12.25 3.9375ZM1.39966 10.3688H0V13.8679H0.692139V12.6938H1.39453C1.64575 12.6938 1.85938 12.6434 2.0354 12.5426C2.21313 12.44 2.34814 12.3016 2.44043 12.1273C2.53442 11.9513 2.58142 11.7539 2.58142 11.5351C2.58142 11.3164 2.53528 11.119 2.44299 10.943C2.35071 10.7669 2.21655 10.6277 2.04053 10.5251C1.86621 10.4209 1.65259 10.3688 1.39966 10.3688ZM1.87646 11.5351C1.87646 11.665 1.85168 11.7761 1.80212 11.8684C1.75427 11.959 1.68506 12.029 1.59448 12.0786C1.50391 12.1264 1.39453 12.1504 1.26636 12.1504H0.689575V10.9199H1.26636C1.45776 10.9199 1.6073 10.9729 1.71497 11.0788C1.82263 11.1848 1.87646 11.3369 1.87646 11.5351ZM2.94185 10.3688V13.8679H4.21846C4.57051 13.8679 4.86189 13.7987 5.0926 13.6603C5.32502 13.5218 5.49849 13.321 5.61299 13.0578C5.72749 12.7947 5.78474 12.4785 5.78474 12.1094C5.78474 11.7419 5.72749 11.4283 5.61299 11.1686C5.5002 10.9088 5.32844 10.7106 5.09773 10.5738C4.86702 10.4371 4.57393 10.3688 4.21846 10.3688H2.94185ZM3.63398 10.9327H4.12617C4.34321 10.9327 4.52095 10.9772 4.65937 11.066C4.79951 11.1549 4.9029 11.2873 4.96956 11.4634C5.03791 11.6394 5.07209 11.859 5.07209 12.1222C5.07209 12.3204 5.05244 12.493 5.01313 12.64C4.97554 12.787 4.91829 12.91 4.84138 13.0091C4.76619 13.1065 4.66877 13.18 4.54915 13.2296C4.42952 13.2774 4.28853 13.3014 4.12617 13.3014H3.63398V10.9327ZM6.90908 12.4759V13.8679H6.21694V10.3688H8.44717V10.9404H6.90908V11.9171H8.31387V12.4759H6.90908Z"
        fill={color}
      />
    </svg>
  );
}
