import { ReactNode } from 'react';
import { CSS, Flex } from '@ghq-abi/design-system';

interface ProfileRootProps {
  children: ReactNode;
  css?: CSS;
}
export function ProfileRoot({ children, css }: ProfileRootProps) {
  return (
    <Flex css={{ justifyContent: 'space-between', width: '100%', ...css }}>
      {children}
    </Flex>
  );
}
