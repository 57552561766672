import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Flex, Label, Select } from '@ghq-abi/design-system';

type SelectAssessmentProps = {
  id?: string;
  value?: string;
  options: string[];
  disabled?: boolean;
  handleValueChange: (value: string) => void;
};
export function SelectAssessment({
  handleValueChange,
  options,
  value,
  id,
  disabled,
}: SelectAssessmentProps) {
  return (
    <Flex direction="column" gap="sm" css={{ width: '106px' }}>
      <Label
        css={{
          fontSize: '$xs',
          textAlign: 'end',
          fontWeight: '$medium',
        }}
        htmlFor={id}
      >
        {id}
      </Label>
      <Select
        value={value}
        onValueChange={handleValueChange}
        disabled={disabled}
      >
        <Select.Trigger
          css={{
            borderColor: '$blue350',
            color: '$blue350',
            fontWeight: 'bold',
          }}
          id={id}
        >
          <Select.Value />
          <Select.Icon>
            <FaChevronDown />
          </Select.Icon>
        </Select.Trigger>
        <Select.Content>
          <Select.ScrollUpButton>
            <FaChevronUp />
          </Select.ScrollUpButton>
          <Select.Viewport>
            {options.map(opt => (
              <Select.Item key={opt} value={opt}>
                <Select.ItemText>{opt}</Select.ItemText>
              </Select.Item>
            ))}
          </Select.Viewport>
          <Select.ScrollDownButton>
            <FaChevronDown />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select>
    </Flex>
  );
}
