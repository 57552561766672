import { Flex, Text } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { MAX_CIRCLE_SIZE } from './constants';
import { Circle, Container, CustomSvg, Outer, slideForward } from './style';

interface CircularProgressProps {
  total: number;
  totalCompleted: number;
}

export function CircularProgress({
  totalCompleted,
  total,
}: CircularProgressProps) {
  const calcProgress = MAX_CIRCLE_SIZE * (1 - totalCompleted / total);

  const { t } = useTranslate('default');

  return (
    <Container>
      <Outer>
        <Flex direction="column" justify="center" align="center">
          <Text css={{ fontSize: '$xs', '@lg': { fontSize: '$md' } }}>
            {t('common.progress')}
          </Text>
          <Text
            css={{
              fontSize: '$xs',
              '@lg': { fontWeight: '$bold', fontSize: '$2xl' },
            }}
          >
            {t('common.amount_of_total', {
              amount: totalCompleted,
              total,
            })}
          </Text>
        </Flex>
      </Outer>
      <CustomSvg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width={120}
        height={120}
        viewBox="0 0 120 120"
      >
        <defs>
          <linearGradient id="gradient-color">
            <stop offset="0%" stopColor="#325A6D" />
            <stop offset="100%" stopColor="#325A6D" />
          </linearGradient>
        </defs>
        <Circle
          cx="60"
          cy="60"
          r={calcProgress === MAX_CIRCLE_SIZE ? 0 : 58}
          style={{
            animation: `${slideForward(
              calcProgress
            )} 600ms ease-in-out forwards`,
          }}
          strokeLinecap="square"
        />
      </CustomSvg>
    </Container>
  );
}
