import React, { ReactNode } from 'react';
import { Box } from '@ghq-abi/design-system';

interface UntouchableWrapperProps {
  children: ReactNode;
}

// This component is used to disable touch events on child elements when used on mobile devices

export function UntouchableWrapper({ children }: UntouchableWrapperProps) {
  return <Box onTouchEnd={e => e.stopPropagation()}>{children}</Box>;
}
