import * as React from 'react';
import Image from 'next/image';
import { type NextRouter, withRouter } from 'next/router';

import { isClient } from '~/shared/utils/environments';

import abiLogo from '~/../public/img/abi_logo_old.png';

type ErrorBoundaryProps = {
  children: React.ReactNode;
  router: NextRouter;
  inApp?: boolean;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundaryComponent extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    const { router } = this.props;
    router.events.on('routeChangeComplete', this.handleRouteChange);
  }

  componentWillUnmount() {
    const { router } = this.props;
    router.events.off('routeChangeComplete', this.handleRouteChange);
  }

  handleRouteChange = () => {
    this.setState({ hasError: false });
  };

  render() {
    const { hasError } = this.state;
    const { children, inApp } = this.props;

    if (hasError) {
      return (
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            ...(!inApp
              ? {
                  minHeight: '100vh',
                }
              : {}),
          }}
        >
          <Image src={abiLogo} placeholder="blur" alt="AB InBev" />
          <h2>Oops, some error occurred!</h2>
          {isClient() && (
            <button
              type="button"
              onClick={() => window.location.reload()}
              style={{
                marginTop: '0.5rem',
                border: 0,
                backgroundColor: 'transparent',
                color: '#0053ad',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              Try again?
            </button>
          )}
        </div>
      );
    }

    return children;
  }
}

export const ErrorBoundary = withRouter(ErrorBoundaryComponent);
