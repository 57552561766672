import { CSS, Heading } from '@ghq-abi/design-system';

type FullNameProps = {
  name?: string;
  disabled?: boolean;
  css?: CSS;
};

export function FullName({ name, disabled, css }: FullNameProps) {
  return (
    <Heading
      size="sm"
      title={name}
      css={{
        color: disabled ? '$gray525' : '$gray750',
        fontSize: '4-5',
        ...css,
      }}
    >
      {name}
    </Heading>
  );
}
