import * as React from 'react';
import { CSS, IconButtonV2 } from '@ghq-abi/design-system';
import {
  FilterCircleFillIcon,
  FilterCircleIcon,
} from '@ghq-abi/design-system-icons';
import { useTranslate } from '@tolgee/react';

type FilterButtonProps = {
  isShowingFilters?: boolean;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  onClick?: () => void;
  css?: CSS;
};

export function FilterButton({
  isShowingFilters,
  disabled,
  size = 'md',
  onClick,
  css,
}: FilterButtonProps) {
  const { t } = useTranslate('default');

  return (
    <IconButtonV2
      css={css}
      type="button"
      variant="secondary"
      size={size}
      title={`${isShowingFilters ? t('common.hide') : t('common.show')} ${t(
        'common.filters'
      )}`}
      icon={isShowingFilters ? <FilterCircleFillIcon /> : <FilterCircleIcon />}
      onClick={onClick}
      disabled={disabled}
    />
  );
}
