import * as React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Box, Flex, Text, useResponsive } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { useAbility } from '~/app/contexts/AbilityContext';
import { useFlags } from '~/app/contexts/FlagsContext';

import oprLogo from '~/../public/img/abi_logo.png';

import { MobileMenu } from '../Nav/components/MobileMenu';

import { StyledActionWrapper } from './styles';

type HomeLinkProps = {
  children: React.ReactNode;
};

function HomeLink({ children }: HomeLinkProps) {
  const flags = useFlags();

  const href = React.useMemo(() => {
    if (flags.home.active) {
      return '/home';
    }

    return null;
  }, [flags.home.active]);

  return href ? (
    <Link href={href} legacyBehavior passHref>
      {children}
    </Link>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
}

export function HeaderActions() {
  const { t } = useTranslate(['menu', 'default']);
  const { isMobile } = useResponsive({ useOnlyAbiTokens: true });

  const ability = useAbility();

  const LogoContainer = ability.can('view', 'Home') ? HomeLink : React.Fragment;

  return (
    <StyledActionWrapper>
      {isMobile && <MobileMenu />}
      <LogoContainer>
        <Flex
          align="center"
          gap="sm"
          as="a"
          title={t('common.navigate_to_home', { ns: 'default' })}
          css={{ textDecoration: 'none' }}
        >
          <Text
            css={{
              fontSize: '$2xl',
              fontWeight: '$bold',
              background:
                'linear-gradient(90deg, #D3A238 3.33%, #F5E003 40.91%)',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent',
            }}
          >
            180/360
          </Text>
          <Box
            css={{
              display: 'none',
              '@lg': { display: 'block' },
              fontSize: 0,
            }}
          >
            <Image src={oprLogo} alt="Ab InBev" placeholder="blur" />
          </Box>
        </Flex>
      </LogoContainer>
    </StyledActionWrapper>
  );
}
