import { Box, styled } from '@ghq-abi/design-system';

export const CarouselWrapper = styled('div', {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  overflow: 'hidden',
});

export const CarouselTrack = styled('div', {
  display: 'flex',
  gap: '$lg',
  width: '300px',
  transition: 'transform 0.3s ease',
});

export const CarouselDots = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  gap: '$sm',
  mt: '$lg',
});

export const Dot = styled(Box, {
  width: '$2-5',
  height: '$2-5',
  borderRadius: '$circle',
  backgroundColor: '$gray475',
  cursor: 'pointer',

  variants: {
    active: {
      true: {
        backgroundColor: '$blue350',
      },
    },
  },
});
