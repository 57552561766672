type CircleCheckPros = {
  color?: string;
  size?: number;
};

export function CircleCheck({ color = '#18794E', size = 18 }: CircleCheckPros) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18 18"
      color={color}
    >
      <path
        d="M8.75033 0.666656C4.16699 0.666656 0.416992 4.41666 0.416992 8.99999C0.416992 13.5833 4.16699 17.3333 8.75033 17.3333C13.3337 17.3333 17.0837 13.5833 17.0837 8.99999C17.0837 4.41666 13.3337 0.666656 8.75033 0.666656ZM12.2503 7.58332L8.25033 11.5833C7.91699 11.9167 7.41699 11.9167 7.08366 11.5833L5.25033 9.74999C4.91699 9.41666 4.91699 8.91666 5.25033 8.58332C5.58366 8.24999 6.08366 8.24999 6.41699 8.58332L7.66699 9.83332L11.0837 6.41666C11.417 6.08332 11.917 6.08332 12.2503 6.41666C12.5837 6.74999 12.5837 7.24999 12.2503 7.58332Z"
        fill="currentColor"
      />
    </svg>
  );
}
