import { CSS, Flex, LinkV2 } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { useAbility } from '~/app/contexts/AbilityContext';
import { useCleanUrl } from '~/shared/hooks';

import { FooterText } from './styles';

type FooterProps = {
  css?: CSS;
  hidden?: boolean;
};

export function Footer({ css, hidden }: FooterProps) {
  const { t } = useTranslate('web-app');
  const { cleanUrl } = useCleanUrl();
  const ability = useAbility();

  if (ability.cannot('view', 'Footer') || hidden) {
    return null;
  }

  return (
    <Flex justify="center" align="end" css={css}>
      <FooterText>
        {`${t('footer.message')} `}
        <LinkV2
          isInText
          href={cleanUrl(t('footer.service_now_link'))}
          target="_blank"
        >
          {t('footer.service_now_label')}
        </LinkV2>
        .
      </FooterText>
    </Flex>
  );
}
