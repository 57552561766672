/* eslint-disable react/no-array-index-key */
import React, { ReactNode, useRef, useState } from 'react';

import { CarouselDots, CarouselTrack, CarouselWrapper, Dot } from './styles';

interface CarouselProps {
  children: ReactNode;
}

// TODO enable to work on desktop
// For a while, the carousel only works on mobile
// Develop a way to get the children width dynamically

export function Carousel({ children }: CarouselProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const trackRef = useRef(null);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const handleSlide = (direction: number) => {
    const totalItems = React.Children.count(children);
    const newIndex = activeIndex + direction;

    if (newIndex < 0 || newIndex >= totalItems) return;

    setActiveIndex(newIndex);
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const touchDiff = touchStartX.current - touchEndX.current;

    if (touchDiff > 0) {
      handleSlide(1); // Slide to the right
    } else {
      handleSlide(-1); // Slide to the left
    }
  };

  const handleDotClick = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <CarouselWrapper>
      <CarouselTrack
        ref={trackRef}
        css={{ transform: `translateX(-${activeIndex * 100}%)` }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {React.Children.map(children, child => child)}
      </CarouselTrack>

      <CarouselDots>
        {React.Children.map(children, (_, index) => (
          <Dot
            active={index === activeIndex}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </CarouselDots>
    </CarouselWrapper>
  );
}
