import { ReactElement } from 'react';
import { styled, Text, Tooltip } from '@ghq-abi/design-system';

import { useClickableTooltip } from '~/shared/hooks';

import { ClickableTooltipTrigger } from '../ClickableTooltipTrigger';

export interface DynamicTooltipProps {
  items: Array<{ label: string; value: string }>;
  children: ReactElement;
}

const StyledTitle = styled(Text, {
  color: '$white',
  fontWeight: '$medium',
  fontSize: '$sm',
  lineHeight: '$4',
});

const StyledText = styled(Text, {
  color: '$white',
  fontWeight: '$normal',
  fontSize: '$sm',
  lineHeight: '$4',
  wordBreak: 'break-all',
});

const StyledContainer = styled('div', {
  display: 'flex',
  py: '$5',
  flexWrap: 'wrap',
  gap: '$md',
});

const StyledContent = styled('div', {
  display: 'flex',
  flex: 'auto',
  flexDirection: 'column',
  width: '$36',
});

export function DynamicTooltip({ items, children }: DynamicTooltipProps) {
  const { rootTooltipProps, openTooltip } = useClickableTooltip();

  return (
    <Tooltip
      {...rootTooltipProps}
      align="start"
      alignOffset={-16}
      css={{ p: '$md' }}
      showArrow
      content={
        <StyledContainer>
          {items.map(item => (
            <StyledContent key={item.label}>
              <StyledTitle>{`${item.label}:`}</StyledTitle>
              <StyledText>{item.value}</StyledText>
            </StyledContent>
          ))}
        </StyledContainer>
      }
    >
      <ClickableTooltipTrigger
        onClick={openTooltip}
        onTouchEnd={e => e.stopPropagation()}
      >
        {children}
      </ClickableTooltipTrigger>
    </Tooltip>
  );
}
