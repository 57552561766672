import * as React from 'react';

type ArrowCurvedProps = {
  size?: number;
  color?: string;
};

export function ArrowCurved({
  size = 18,
  color = '#0055B0',
}: ArrowCurvedProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18 18"
      color={color}
      fill="none"
    >
      <path
        d="M12 3.75L15.75 7.5L12 11.25"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 7.5H9.75C5.60786 7.5 2.25 10.8579 2.25 15V15.75"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
