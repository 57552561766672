type ChevronProps = {
  size?: number;
  color?: string;
  rotate?: number;
};

export function Chevron({
  color = 'black',
  size = 24,
  rotate = 0,
}: ChevronProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      transform={`rotate(${rotate})`} // Rotate around the center of the SVG
    >
      <path
        d="M16 4L8 12L16 20"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
