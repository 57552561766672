import { useEffect } from 'react';

import { useAbility } from '~/app/contexts/AbilityContext';

export const useStellarWidget = () => {
  const abilities = useAbility();

  const stellarWidgetUrl = process.env.NEXT_PUBLIC_STELLAR_WIDGET_URL;
  const stellarWidgetConnectorId =
    process.env.NEXT_PUBLIC_STELLAR_WIDGET_CONNECTOR_ID;
  const stellarWidgetTemplateId =
    process.env.NEXT_PUBLIC_STELLAR_WIDGET_TEMPLATE_ID;
  const stellarWidgetEnabled = process.env.NEXT_PUBLIC_STELLAR_WIDGET_ENABLED;
  const stellarWidgetOptions = `{"draggableWidget":"true"}`;

  useEffect(() => {
    // Parsing to string to standardize as I've noticed two different behaviors when injecting booleans
    // (injecting as true boolean type and injecting as string)
    const showStellarWidget =
      stellarWidgetEnabled?.toString() === 'true' &&
      !!stellarWidgetUrl &&
      !!stellarWidgetConnectorId &&
      !!stellarWidgetTemplateId &&
      abilities.can('view', 'Stellar');

    if (showStellarWidget) {
      const script = document.createElement('script');
      script.setAttribute('src', `${stellarWidgetUrl}`);
      script.setAttribute('data-connector-id', `${stellarWidgetConnectorId}`);
      script.setAttribute('data-template-id', `${stellarWidgetTemplateId}`);
      script.setAttribute('async', '');

      if (stellarWidgetOptions) {
        script.setAttribute('options', stellarWidgetOptions);
      }

      const root = document.getElementsByTagName('body')[0] as HTMLElement;
      root.appendChild(script);
    }
  }, [
    stellarWidgetConnectorId,
    stellarWidgetEnabled,
    stellarWidgetOptions,
    stellarWidgetTemplateId,
    stellarWidgetUrl,
    abilities,
  ]);
};
