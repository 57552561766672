import { useQueryClient } from '@tanstack/react-query';

import { GET_TASKS_QUERY_KEY } from '~/entities/Home/api';

export function useInvalidateQueries() {
  const client = useQueryClient();

  return {
    invalidateTasksToDo: () => client.invalidateQueries([GET_TASKS_QUERY_KEY]),
  };
}
