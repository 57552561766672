import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { signIn, signOut } from 'next-auth/react';
import { useTranslate } from '@tolgee/react';
import nprogress from 'nprogress';

import { useAbility } from '~/app/contexts/AbilityContext';
import { useAuth } from '~/app/contexts/AuthContext';
import { useSessionInfo } from '~/app/contexts/SessionInfoContext';
import { AuthenticatedUser, ProxiedAuth } from '~/shared/auth/types/next-auth';
import { Icon } from '~/shared/components';
import { SignOut } from '~/shared/components/icons/SignOut';

type UseUserDropdownProps = {
  user: AuthenticatedUser | ProxiedAuth;
};

export const useUserDropdown = ({ user }: UseUserDropdownProps) => {
  const { t } = useTranslate(['default']);
  const { sessionInfo: session } = useSessionInfo();
  const router = useRouter();
  const { notifyChangedProxy } = useAuth();
  const ability = useAbility();

  const isProxying = !!session?.user?.proxiedAs;

  async function handleSignOut() {
    try {
      await fetch('/api/destroy-cookies');
    } finally {
      signOut({ redirect: false });
    }
  }

  const goToProxy = useCallback(async () => {
    router.push('/proxy');
  }, [router]);

  const handleStopProxy = useCallback(async () => {
    if (!session) {
      return;
    }

    nprogress.start();
    await signIn('register-proxy', {
      user: JSON.stringify({ ...session.user, proxiedAs: null }),
    });
    notifyChangedProxy();
  }, [notifyChangedProxy, session]);

  const items = useMemo(() => {
    const dropdownItems = [
      {
        label: t('common.sign_out'),
        onSelect: handleSignOut,
        icon: <SignOut />,
      },
    ];

    if (ability.can('proxy', 'User')) {
      dropdownItems.unshift({
        label: t(isProxying ? 'common.stop_proxy' : 'common.proxy_as_user'),
        onSelect: isProxying ? handleStopProxy : goToProxy,
        icon: isProxying ? (
          <Icon.StopProxy size={18} />
        ) : (
          <Icon.Proxy size={18} />
        ),
      });
    }

    return dropdownItems;
  }, [ability, goToProxy, handleStopProxy, isProxying, t]);

  const profileInfo = {
    name: user.name ?? '',
    urlImage:
      process.env.NEXT_PUBLIC_PEOPLE_PLATFORM_PHOTO_API_URL && user.globalId
        ? `${process.env.NEXT_PUBLIC_PEOPLE_PLATFORM_PHOTO_API_URL}/thumb/${user.globalId}`
        : undefined,
    zone: user.zone ?? '',
  };

  return {
    items,
    profileInfo,
  };
};
