type ApprovedProps = {
  size?: number;
  color?: string;
};

export function Approved({ color = '#18794E', size = 16 }: ApprovedProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7 0.5C5.61553 0.5 4.26216 0.910543 3.11101 1.67971C1.95987 2.44888 1.06266 3.54213 0.532846 4.82122C0.00303298 6.1003 -0.13559 7.50777 0.134506 8.86563C0.404603 10.2235 1.07129 11.4708 2.05026 12.4497C3.02922 13.4287 4.2765 14.0954 5.63437 14.3655C6.99224 14.6356 8.3997 14.497 9.67879 13.9672C10.9579 13.4373 12.0511 12.5401 12.8203 11.389C13.5895 10.2378 14 8.88447 14 7.5C14 5.64348 13.2625 3.86301 11.9497 2.55025C10.637 1.2375 8.85652 0.5 7 0.5ZM6 10.2954L3.5 7.7954L4.2953 7L6 8.7046L9.705 5L10.5029 5.79295L6 10.2954Z"
        fill={color}
      />
    </svg>
  );
}
