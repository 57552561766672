import { Heading } from '@ghq-abi/design-system';

type PageHeadingProps = React.ComponentProps<typeof Heading> & {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

export function PageHeading({ ...props }: PageHeadingProps) {
  return (
    <Heading
      {...props}
      css={{
        fontWeight: '$medium',
        fontSize: '$7',
        mb: '$md',
        color: '$gray750',
        display: 'flex',
        alignItems: 'center',
        ...props.css,
      }}
    />
  );
}
