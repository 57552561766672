import React from 'react';
import Link from 'next/link';
import { ButtonV2 } from '@ghq-abi/design-system';

import { TalentCardIcon } from '../../icons';

type TalentCardProps = {
  title: string;
  link: string;
};
export const TalentCard: React.FC<TalentCardProps> = ({ title, link }) => {
  return (
    <Link href={link} target="_blank">
      <ButtonV2 rightIcon={<TalentCardIcon />} variant="secondary" size="md">
        {title}
      </ButtonV2>
    </Link>
  );
};
