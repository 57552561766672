import * as React from 'react';
import { Box, CSS } from '@ghq-abi/design-system';

type IconButtonProps = {
  title?: string;
  disabled?: boolean;
  css?: CSS;
  onClick: () => void;
  onTouchEnd?: React.TouchEventHandler<HTMLDivElement>;
  icon: React.ReactNode;
};

export function IconButton({
  title,
  disabled,
  css,
  icon,
  onClick,
  onTouchEnd,
  ...props
}: IconButtonProps) {
  return (
    <Box
      css={{
        height: '$4',
        pointerEvents: disabled ? 'none' : 'all',
        cursor: disabled ? 'not-allowed' : 'pointer',
        color: disabled ? '$gray450' : '$gray800',
        ...css,
      }}
      tabIndex={0}
      role="button"
      id={title}
      title={title}
      onClick={onClick}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          onClick();
        }
      }}
      aria-disabled={disabled}
      onTouchEnd={onTouchEnd}
      {...props}
    >
      {icon}
    </Box>
  );
}
