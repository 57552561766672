type StartProxyProps = {
  size?: number;
  color?: string;
};

export function StartProxy({
  size = 28,
  color = 'currentColor',
}: StartProxyProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 1C2.23478 1 1.98043 1.10536 1.79289 1.29289C1.60536 1.48043 1.5 1.73478 1.5 2V14C1.5 14.2652 1.60536 14.5196 1.79289 14.7071C1.98043 14.8946 2.23478 15 2.5 15H14.5C14.7652 15 15.0196 14.8946 15.2071 14.7071C15.3946 14.5196 15.5 14.2652 15.5 14V2C15.5 1.73478 15.3946 1.48043 15.2071 1.29289C15.0196 1.10536 14.7652 1 14.5 1H10C9.73478 1 9.48043 1.10536 9.29289 1.29289C9.10536 1.48043 9 1.73478 9 2V9.293L11.646 6.646C11.7399 6.55211 11.8672 6.49937 12 6.49937C12.1328 6.49937 12.2601 6.55211 12.354 6.646C12.4479 6.73989 12.5006 6.86722 12.5006 7C12.5006 7.13278 12.4479 7.26011 12.354 7.354L8.854 10.854C8.80755 10.9006 8.75238 10.9375 8.69163 10.9627C8.63089 10.9879 8.56577 11.0009 8.5 11.0009C8.43423 11.0009 8.36911 10.9879 8.30837 10.9627C8.24762 10.9375 8.19245 10.9006 8.146 10.854L4.646 7.354C4.59951 7.30751 4.56264 7.25232 4.53748 7.19158C4.51232 7.13084 4.49937 7.06574 4.49937 7C4.49937 6.93426 4.51232 6.86916 4.53748 6.80842C4.56264 6.74768 4.59951 6.69249 4.646 6.646C4.69249 6.59951 4.74768 6.56264 4.80842 6.53748C4.86916 6.51232 4.93426 6.49937 5 6.49937C5.06574 6.49937 5.13084 6.51232 5.19158 6.53748C5.25232 6.56264 5.30751 6.59951 5.354 6.646L8 9.293V2C8 1.46957 8.21071 0.960859 8.58579 0.585786C8.96086 0.210714 9.46957 0 10 0L14.5 0C15.0304 0 15.5391 0.210714 15.9142 0.585786C16.2893 0.960859 16.5 1.46957 16.5 2V14C16.5 14.5304 16.2893 15.0391 15.9142 15.4142C15.5391 15.7893 15.0304 16 14.5 16H2.5C1.96957 16 1.46086 15.7893 1.08579 15.4142C0.710714 15.0391 0.5 14.5304 0.5 14V2C0.5 1.46957 0.710714 0.960859 1.08579 0.585786C1.46086 0.210714 1.96957 0 2.5 0L5 0C5.13261 0 5.25979 0.0526784 5.35355 0.146447C5.44732 0.240215 5.5 0.367392 5.5 0.5C5.5 0.632608 5.44732 0.759785 5.35355 0.853553C5.25979 0.947322 5.13261 1 5 1H2.5Z"
        fill={color}
      />
    </svg>
  );
}
