import * as React from 'react';

type DeclineProps = {
  size?: number;
  color?: string;
};

export function Decline({ size = 16, color = '#DC3D43' }: DeclineProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 15 14"
      color={color}
    >
      <path
        d="M7.5 0C3.6 0 0.5 3.1 0.5 7C0.5 10.9 3.6 14 7.5 14C11.4 14 14.5 10.9 14.5 7C14.5 3.1 11.4 0 7.5 0ZM10.2 10.5L7.5 7.8L4.8 10.5L4 9.7L6.7 7L4 4.3L4.8 3.5L7.5 6.2L10.2 3.5L11 4.3L8.3 7L11 9.7L10.2 10.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
