import * as React from 'react';
import { useAbility as useCaslAbility } from '@casl/react';

import type { AppAbility } from '~/shared/auth/permissions';

export const AbilityContext = React.createContext({} as AppAbility);

type AbilityProviderProps = {
  ability: AppAbility;
  children: React.ReactNode;
};

export function AbilityProvider({ ability, children }: AbilityProviderProps) {
  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
}

export function useAbility() {
  return useCaslAbility(AbilityContext);
}
