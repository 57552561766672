import { LanguageDropdown, Skeleton } from '@ghq-abi/design-system';
import { LanguagesEnum } from 'abi-opr-common-types';

import { useLanguageDropdown } from './hooks';
import { LanguageDropdownItem } from './types';

type LanguageDropdownProps = {
  currentLanguage?: string;
};

export const LanguageDropdownHeader = ({
  currentLanguage,
}: LanguageDropdownProps) => {
  const {
    availableLanguages,
    selectedLanguageLabel,
    isLoadingLanguages,
    handleLanguageChange,
  } = useLanguageDropdown(currentLanguage);

  if (isLoadingLanguages) {
    return <Skeleton variant="rectangle" height={46} width={124} />;
  }

  const languageOptions = Object.keys(LanguagesEnum).map(lang => ({
    label: availableLanguages.find(l => l.code === lang)?.label || lang,
    onSelect: () =>
      handleLanguageChange(
        availableLanguages.find(l => l.code === lang) as LanguageDropdownItem
      ),
  }));

  return (
    <LanguageDropdown value={selectedLanguageLabel} items={languageOptions} />
  );
};
