import * as React from 'react';
import { JWT } from 'next-auth/jwt';

type SessionInfoContextData = {
  sessionInfo: JWT;
};

type AuthProviderProps = {
  children: React.ReactNode;
  sessionInfo: JWT;
};

const SessionInfoContext = React.createContext({} as SessionInfoContextData);

export function SessionInfoProvider({
  children,
  sessionInfo,
}: AuthProviderProps) {
  const providerValue = React.useMemo(() => ({ sessionInfo }), [sessionInfo]);

  return (
    <SessionInfoContext.Provider value={providerValue}>
      {children}
    </SessionInfoContext.Provider>
  );
}

export function useSessionInfo() {
  const context = React.useContext(SessionInfoContext);

  if (!context) {
    throw new Error(
      'useSessionInfo must be used within an SessionInfoProvider'
    );
  }

  return context;
}
