import { styled } from '@ghq-abi/design-system';

export const StyledActionWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$sm',
  '@lg': {
    gap: '$3-5',
  },
});
