import Script from 'next/script';
import type { JWT } from 'next-auth/jwt';

type NpsMetricScriptProps = {
  session?: JWT | null;
};

function NpsMetricScript({ session }: NpsMetricScriptProps) {
  return (
    <>
      <Script
        type="text/javascript"
        src={process.env.NEXT_PUBLIC_METRICS_URL}
        strategy="afterInteractive"
      />
      <Script id="plugin" strategy="afterInteractive">
        {`
            var metricsPlugin;
            setTimeout(() => {
                metricsPlugin.plugin("${session?.user?.globalId}","LCM","${session?.user?.preferredLanguage}")
            }, 2000)
        `}
      </Script>
    </>
  );
}

export const NPS = {
  Script: NpsMetricScript,
};
