import { Text } from '@ghq-abi/design-system';

type GlobalIdProps = {
  globalId?: string;
  disabled?: boolean;
};

export function GlobalId({ globalId, disabled }: GlobalIdProps) {
  return (
    <Text
      css={{
        display: 'block',
        fontSize: '$xs',
        color: disabled ? '$gray525' : '$gray575',
        fontWeight: '$normal',
      }}
    >
      {globalId}
    </Text>
  );
}
