import { Skeleton, SkeletonProps } from '@ghq-abi/design-system';

interface SkeletonsProp extends SkeletonProps {
  quantity?: number;
}

export function Skeletons({
  quantity = 1,
  height,
  width = '$full',
  ...rest
}: SkeletonsProp) {
  return (
    <>
      {Array.from({ length: quantity }, (_, i) => i).map(i => (
        <Skeleton
          key={i}
          variant="rectangle"
          css={{ width, minHeight: height, m: 0 }}
          {...rest}
        />
      ))}
    </>
  );
}
