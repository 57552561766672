import { CSS, Flex, Heading, Text } from '@ghq-abi/design-system';

import { Avatar } from '../Avatar';

type PersonalProfileProps = {
  name?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  globalId?: string;
  css?: CSS;
  customHeadingWidth?: string;
};

export function PersonalProfile({
  name = '',
  firstName = '',
  lastName = '',
  jobTitle = '',
  globalId = '',
  css,
  customHeadingWidth,
}: PersonalProfileProps) {
  return (
    <Flex css={{ ...css }} gap="sm" align="center" justify="center">
      <Avatar name={name} globalId={globalId ?? ''} size="sm" />
      <Flex css={{ flexGrow: 1 }} direction="column" justify="center">
        <Heading
          size="sm"
          title={name}
          css={{
            lineHeight: '$normal',
            color: '$gray750',
            width: customHeadingWidth || 'auto',
            fontSize: '$sm',
            limitLines: 1,
            span: { fontWeight: '$medium' },
          }}
        >
          <span>
            {firstName} {lastName}
          </span>
        </Heading>
        <Text
          title={jobTitle}
          css={{ maxHeight: 36, limitLines: 1, fontSize: '$xs' }}
        >
          {jobTitle}
        </Text>
      </Flex>
    </Flex>
  );
}
