import * as React from 'react';
import { Avatar } from '@ghq-abi/design-system';

type AvatarProps = React.ComponentProps<typeof Avatar> & {
  globalId?: string;
};

const PHOTO_API = process.env.NEXT_PUBLIC_PEOPLE_PLATFORM_PHOTO_API_URL;

export function ProfileAvatar({ globalId, ...rest }: AvatarProps) {
  return (
    <Avatar
      src={PHOTO_API && globalId ? `${PHOTO_API}/thumb/${globalId}` : undefined}
      {...rest}
    />
  );
}
