import { ChangeEvent } from 'react';
import { CSS, Flex, Label, TextField, theme } from '@ghq-abi/design-system';
import { SearchIcon } from '@ghq-abi/design-system-icons';

type FilterTextFieldProps = {
  id: string;
  value: string;
  placeholder: string;
  label?: string;
  isLoading: boolean;
  disabled: boolean;
  showIcon?: boolean;
  css?: CSS;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  containerCss?: CSS;
};

export function FilterTextField({
  id,
  label,
  value,
  placeholder,
  isLoading,
  disabled,
  onChange,
  css,
  containerCss,
  name,
  showIcon = true,
}: FilterTextFieldProps) {
  return (
    <Flex direction="column" gap="md" css={css}>
      {label && (
        <Label
          htmlFor={id}
          css={{
            fontWeight: '$medium',
            color: '$gray750',
            fontSize: '$sm',
          }}
        >
          {label}
        </Label>
      )}
      <TextField
        id={id}
        name={name}
        disabled={isLoading || disabled}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        rightIcon={
          showIcon ? (
            <SearchIcon
              style={{
                width: theme.sizes[4].value,
                height: theme.sizes[4].value,
              }}
            />
          ) : null
        }
        css={{ fontSize: '$sm', height: '$10' }}
        containerCss={{ flex: 1, ...containerCss }}
      />
    </Flex>
  );
}
