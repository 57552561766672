import React from 'react';
import { Tooltip } from '@ghq-abi/design-system';

import { useClickableTooltip } from '~/shared/hooks';

import { ClickableTooltipTrigger } from '../../ClickableTooltipTrigger';
import { InfoCircleFilled } from '../../icons';

type InfoTooltipProps = {
  tooltipText: string;
};
export const InfoTooltip: React.FC<InfoTooltipProps> = ({ tooltipText }) => {
  const { rootTooltipProps, openTooltip } = useClickableTooltip();

  return (
    <Tooltip showArrow content={tooltipText} {...rootTooltipProps}>
      <ClickableTooltipTrigger
        onClick={openTooltip}
        onTouchEnd={e => e.stopPropagation()}
      >
        <InfoCircleFilled color="#191F2E" />
      </ClickableTooltipTrigger>
    </Tooltip>
  );
};
