import React from 'react';

type TalentCardIconProps = {
  color?: string;
};

export const TalentCardIcon: React.FC<TalentCardIconProps> = ({
  color = '#325A6D',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
  >
    <path
      d="M4.5 2C4.22386 2 4 2.22386 4 2.5C4 2.77614 4.22386 3 4.5 3H7.5C7.77614 3 8 2.77614 8 2.5C8 2.22386 7.77614 2 7.5 2H4.5Z"
      fill={color}
    />
    <path
      d="M9 8C9 9.65685 7.65685 11 6 11C4.34315 11 3 9.65685 3 8C3 6.34315 4.34315 5 6 5C7.65685 5 9 6.34315 9 8Z"
      fill={color}
    />
    <path
      d="M2.5 0C1.11929 0 0 1.11929 0 2.5V14C0 15.1046 0.895431 16 2 16H10C11.1046 16 12 15.1046 12 14V2.5C12 1.11929 10.8807 0 9.5 0H2.5ZM1 2.5C1 1.67157 1.67157 1 2.5 1H9.5C10.3284 1 11 1.67157 11 2.5V13.295C10.8152 13.1446 10.5619 12.9719 10.2236 12.8028C9.39204 12.387 8.0628 12 6 12C3.9372 12 2.60796 12.387 1.77639 12.8028C1.43811 12.9719 1.18477 13.1446 1 13.295V2.5Z"
      fill={color}
    />
  </svg>
);
