type StopProxyProps = {
  size?: number;
  color?: string;
};

export function StopProxy({
  size = 28,
  color = 'currentColor',
}: StopProxyProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8333 15.5C9.80555 15.5 8.84389 15.3089 7.94833 14.9267C7.05222 14.545 6.26389 14.0208 5.58333 13.3542L6.77083 12.1667C7.29861 12.6806 7.90972 13.0867 8.60417 13.385C9.29861 13.6839 10.0417 13.8333 10.8333 13.8333C12.4444 13.8333 13.8194 13.2639 14.9583 12.125C16.0972 10.9861 16.6667 9.61111 16.6667 8C16.6667 6.38889 16.0972 5.01389 14.9583 3.875C13.8194 2.73611 12.4444 2.16667 10.8333 2.16667C9.22222 2.16667 7.84722 2.73611 6.70833 3.875C5.56944 5.01389 5 6.38889 5 8V8.14583L6.52083 6.625L7.70833 7.79167L4.16667 11.3333L0.625 7.79167L1.8125 6.625L3.33333 8.16667V8C3.33333 6.95833 3.53139 5.9825 3.9275 5.0725C4.32306 4.16306 4.85778 3.37139 5.53167 2.6975C6.205 2.02417 6.99667 1.48944 7.90667 1.09333C8.81611 0.697778 9.79167 0.5 10.8333 0.5C11.875 0.5 12.8508 0.697778 13.7608 1.09333C14.6703 1.48944 15.4619 2.02417 16.1358 2.6975C16.8092 3.37139 17.3439 4.16306 17.74 5.0725C18.1356 5.9825 18.3333 6.95833 18.3333 8C18.3333 10.0833 17.6042 11.8542 16.1458 13.3125C14.6875 14.7708 12.9167 15.5 10.8333 15.5ZM9.16667 11.3333C8.93055 11.3333 8.73278 11.2533 8.57333 11.0933C8.41333 10.9339 8.33333 10.7361 8.33333 10.5V8C8.33333 7.76389 8.41333 7.56583 8.57333 7.40583C8.73278 7.24639 8.93055 7.16667 9.16667 7.16667V6.33333C9.16667 5.875 9.33 5.4825 9.65667 5.15583C9.98278 4.82972 10.375 4.66667 10.8333 4.66667C11.2917 4.66667 11.6842 4.82972 12.0108 5.15583C12.3369 5.4825 12.5 5.875 12.5 6.33333V7.16667C12.7361 7.16667 12.9342 7.24639 13.0942 7.40583C13.2536 7.56583 13.3333 7.76389 13.3333 8V10.5C13.3333 10.7361 13.2536 10.9339 13.0942 11.0933C12.9342 11.2533 12.7361 11.3333 12.5 11.3333H9.16667ZM10 7.16667L11.6667 7.16667V6.33333C11.6667 6.09722 11.5869 5.89917 11.4275 5.73917C11.2675 5.57972 11.0694 5.5 10.8333 5.5C10.5972 5.5 10.3994 5.57972 10.24 5.73917C10.08 5.89917 10 6.09722 10 6.33333V7.16667Z"
        fill={color}
      />
    </svg>
  );
}
