import { Flex } from '@ghq-abi/design-system';

import { StatusCircle, StatusText } from '../styles';

type StatusBadgeProps = {
  status?: string;
  color?: string;
};

export function StatusBadge({ color, status }: StatusBadgeProps) {
  return (
    <Flex align="center" gap="xs">
      <StatusCircle
        css={{
          bg: color,
        }}
      />

      <StatusText>{status}</StatusText>
    </Flex>
  );
}
