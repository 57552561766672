export function Excel() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6858 11.5123L6.38403 10.0498V20.8566C6.38413 20.974 6.40735 21.0902 6.45237 21.1986C6.49739 21.307 6.56332 21.4055 6.64641 21.4885C6.72949 21.5714 6.8281 21.6372 6.93661 21.682C7.04511 21.7269 7.16138 21.7499 7.27878 21.7498H21.6038C21.7213 21.7501 21.8377 21.7272 21.9464 21.6825C22.0551 21.6377 22.1539 21.572 22.2371 21.489C22.3204 21.4061 22.3864 21.3075 22.4316 21.199C22.4767 21.0904 22.4999 20.9741 22.5 20.8566V16.8748L14.6858 11.5123Z"
        fill="#185C37"
      />
      <path
        d="M14.6858 2.25H7.27878C7.16138 2.2499 7.04511 2.27293 6.93661 2.31776C6.8281 2.3626 6.72949 2.42837 6.64641 2.51132C6.56332 2.59426 6.49739 2.69276 6.45237 2.80119C6.40735 2.90962 6.38413 3.02585 6.38403 3.14325V7.125L14.6858 12L19.0815 13.4625L22.5 12V7.125L14.6858 2.25Z"
        fill="#21A366"
      />
      <path d="M6.38403 7.125H14.6858V12H6.38403V7.125Z" fill="#107C41" />
      <path
        opacity="0.1"
        d="M12.3255 6.1499H6.38403V18.3374H12.3255C12.5623 18.3362 12.789 18.2418 12.9567 18.0746C13.1243 17.9074 13.2193 17.6809 13.221 17.4442V7.04315C13.2193 6.80641 13.1243 6.57989 12.9567 6.4127C12.789 6.2455 12.5623 6.15108 12.3255 6.1499Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.8373 6.6377H6.38403V18.8252H11.8373C12.074 18.824 12.3008 18.7296 12.4684 18.5624C12.636 18.3952 12.731 18.1687 12.7328 17.9319V7.53095C12.731 7.2942 12.636 7.06769 12.4684 6.90049C12.3008 6.73329 12.074 6.63887 11.8373 6.6377Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.8373 6.6377H6.38403V17.8502H11.8373C12.074 17.849 12.3008 17.7546 12.4684 17.5874C12.636 17.4202 12.731 17.1937 12.7328 16.9569V7.53095C12.731 7.2942 12.636 7.06769 12.4684 6.90049C12.3008 6.73329 12.074 6.63887 11.8373 6.6377Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.349 6.6377H6.38403V17.8502H11.349C11.5858 17.849 11.8125 17.7546 11.9802 17.5874C12.1478 17.4202 12.2428 17.1937 12.2445 16.9569V7.53095C12.2428 7.2942 12.1478 7.06769 11.9802 6.90049C11.8125 6.73329 11.5858 6.63887 11.349 6.6377Z"
        fill="black"
      />
      <path
        d="M2.3955 6.6377H11.349C11.5862 6.6375 11.8137 6.73147 11.9816 6.89897C12.1496 7.06646 12.2441 7.29377 12.2445 7.53095V16.4694C12.2441 16.7066 12.1496 16.9339 11.9816 17.1014C11.8137 17.2689 11.5862 17.3629 11.349 17.3627H2.3955C2.27804 17.3629 2.16168 17.3399 2.05309 17.2951C1.9445 17.2504 1.84581 17.1846 1.76264 17.1016C1.67948 17.0187 1.61348 16.9202 1.56841 16.8117C1.52334 16.7032 1.5001 16.5869 1.5 16.4694V7.53095C1.5001 7.41348 1.52334 7.29719 1.56841 7.18871C1.61348 7.08023 1.67948 6.9817 1.76264 6.89875C1.84581 6.81579 1.9445 6.75004 2.05309 6.70525C2.16168 6.66045 2.27804 6.6375 2.3955 6.6377Z"
        fill="url(#paint0_linear_2034_49057)"
      />
      <path
        d="M4.27502 14.9047L6.15827 11.9917L4.43327 9.09521H5.81852L6.75977 10.95C6.84677 11.1255 6.90977 11.256 6.93827 11.343H6.95102C7.01252 11.202 7.07777 11.0662 7.14602 10.9335L8.15252 9.09821H9.42752L7.65827 11.9782L9.47252 14.907H8.11577L7.02827 12.8737C6.97778 12.7863 6.93487 12.6947 6.90002 12.6H6.88202C6.85031 12.6923 6.80803 12.7806 6.75602 12.8632L5.63627 14.9047H4.27502Z"
        fill="white"
      />
      <path
        d="M21.6045 2.25H14.6858V7.125H22.5V3.14325C22.4999 3.02579 22.4767 2.90949 22.4316 2.80102C22.3866 2.69254 22.3206 2.59401 22.2374 2.51105C22.1542 2.4281 22.0555 2.36234 21.9469 2.31755C21.8384 2.27276 21.722 2.2498 21.6045 2.25Z"
        fill="#33C481"
      />
      <path d="M14.6858 12H22.5V16.875H14.6858V12Z" fill="#107C41" />
      <defs>
        <linearGradient
          id="paint0_linear_2034_49057"
          x1="3.3705"
          y1="5.9357"
          x2="10.374"
          y2="18.0647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18884F" />
          <stop offset="0.5" stopColor="#117E43" />
          <stop offset="1" stopColor="#0B6631" />
        </linearGradient>
      </defs>
    </svg>
  );
}
