import * as React from 'react';
import { CSS, theme, Tooltip } from '@ghq-abi/design-system';
import { QuestionCircleIcon } from '@ghq-abi/design-system-icons';

import { useClickableTooltip } from '~/shared/hooks';

import { ClickableTooltipTrigger } from '../ClickableTooltipTrigger';

type QuestionTooltipProps = Omit<
  React.ComponentProps<typeof Tooltip>,
  'children'
> & {
  iconSize?: number;
  buttonCss?: CSS;
  icon?: React.ReactNode;
};

export function QuestionTooltip({
  iconSize = 14,
  buttonCss,
  icon: Icon,
  ...props
}: QuestionTooltipProps) {
  const { rootTooltipProps, openTooltip } = useClickableTooltip();

  return (
    <Tooltip showArrow alignOffset={-iconSize} {...props} {...rootTooltipProps}>
      <ClickableTooltipTrigger
        onClick={openTooltip}
        onTouchEnd={e => e.stopPropagation()}
        css={{
          ...buttonCss,
        }}
      >
        {Icon || (
          <QuestionCircleIcon
            width={14}
            height={14}
            color={theme.colors.blue350.value}
          />
        )}
      </ClickableTooltipTrigger>
    </Tooltip>
  );
}
